.build-your-own {
	position: relative;
	overflow: hidden;

	&__body {
		.btn {
			display: flex;
			margin-bottom: 3.5rem;

			@include minW(768) {
				margin-bottom: 0;
			}
		}
	}

	&__choose {
		.label {
			margin: 4rem 0 1.2rem;
		}

		.select {
			margin-bottom: 3.5rem;
		}
	}

	&__desc {
		color: $dark-grey-color;
	}

	.row {
		align-items: center;

		&__col {
			position: relative;
		}
	}
}

.background-car {
	position: absolute;
	top: 0;
	left: 0;
	width: 50vw;
	left: 30%;
	height: 100%;

	@include minW(768) {
		left: 50%;
	}

	&__item {
		width: 100vw;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		transform: translate(100%);

		@include minW(768) {
			-webkit-clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%);
			clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%);
			width: 100vw;
		}
	}
}

.product-car {
	position: relative;
	z-index: 2;

	&__item {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		z-index: 0;

		&:first-child {
			position: relative;
			opacity: 1;
		}

		&.is-active {
			z-index: 1;
		}

		&.is-animate {
			.card-product {
				animation: o 2.5s cubic-bezier(0.22, 0.67, 0.37, 0.93) 0.5s;
				animation-fill-mode: backwards;

				&__car {
					animation: q 1s cubic-bezier(0.45, 0.45, 0.37, 0.93) 2.8s;
					animation-fill-mode: backwards;
				}

				&__wheel {
					animation: p 2.5s cubic-bezier(0.22, 0.67, 0.37, 0.93) 0.7s;
					animation-fill-mode: backwards;
				}
			}
		}
	}

	&__paging {
		font-size: 1.2rem;
		line-height: 1;

		span {
			font-weight: 700;
			padding: 0 5px;
		}
	}

	&__controls {
		position: relative;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 1.5rem;

		@include minW(768) {
			margin-top: -7rem;
		}
	}

	&__arrow {
		background-color: transparent;
		border: 0;
		outline: 0;
		font-size: 0;
		width: 1.8rem;
		height: 1.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 2px 0 0;

		&:focus {
			outline: 0;
		}

		&::before {
			content: '';
			display: block;
			width: 5px;
			height: 5px;
			border-left: 2px solid $black-color;
			border-bottom: 2px solid $black-color;
		}

		&--prev {
			margin-right: 5px;

			&::before {
				transform: rotate(45deg);
			}
		}

		&--next {
			margin-left: 5px;

			&::before {
				transform: rotate(-135deg);
			}
		}
	}
}

.color-car {
	position: relative;
	display: flex;
	padding: 1rem 0;
	justify-content: center;
	margin-bottom: 1rem;
	z-index: 2;

	&__item {
		margin: 0 1rem;
		cursor: pointer;

		&.is-active {
			pointer-events: none;
			cursor: default;

			.color-car {
				&__icon {
					transform: scale(1.75);
				}

				&__name {
					opacity: 1;
				}
			}
		}

		.color-car {
			&__name {
				opacity: 0;
			}
		}
	}

	&__icon {
		margin: 0;
		display: block;
		width: 2.4rem;
		height: 2.4rem;
		border-radius: 100%;
		cursor: pointer;
		text-align: center;
		border: none;
		box-shadow: inset 0 1px 4px 0 rgb($black-color, 0.6);
		transition: all 0.25s ease;

		@include hover {
			transform: scale(1.3);
		}
	}

	&__name {
		position: absolute;
		left: 50%;
		transform: translate(-50%);
		text-transform: uppercase;
		font-size: 1.2rem;
		line-height: 1;
		font-weight: 400;
		color: #383838;
	}
}

.color-text-car {
	position: relative;
	display: flex;
	padding: 1rem 0;
	justify-content: center;
	padding-bottom: 10rem;
	font-size: 1.2rem;
	line-height: 1.6rem;
	letter-spacing: 2px;
	text-transform: uppercase;
	z-index: 2;

	&__item {
		margin: 0 1rem;
		display: none;

		&:first-child {
			display: block;
		}
	}
}

.model-car {
	position: relative;
	margin: 0 -2rem;

	@include minW(768) {
		margin: 0;
	}

	&__item {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		z-index: 0;

		&:first-child {
			position: relative;
			opacity: 1;
			z-index: 1;
		}

		&.is-white {
			.product-car {
				&__arrow {
					&::before,
					&::after {
						border-color: $white-color;
					}
				}

				&__paging {
					color: $white-color;
				}
			}

			.color-text-car {
				color: $white-color;
			}
		}
	}
}

.card-product {
	position: relative;
	position: absolute;

	&__shadow {
		position: absolute;
		top: 0;
		left: 0;
	}

	&__wheel {
		position: absolute;
		left: 15.5%;
		top: 57.9%;
		width: 14.2%;
		height: auto;

		&--after {
			left: auto;
			right: 16.5%;
		}
	}
}

.pajero-sport-360 {
	.card-product__wheel {
		left: 12.8%;
		top: 60%;
		width: 14.8%;

		&--after {
			right: 18.65%;
			top: 58.99%;
			left: auto;
		}
	}
}

.triton-360 {
	.card-product__wheel {
		left: 15.3%;
		top: 57.2%;
		width: 14.8%;

		&--after {
			right: 15.8%;
			top: 58%;
			left: auto;
		}
	}
}

.attrage-360 {
	.card-product__wheel {
		left: 14%;
		top: 57%;
		width: 13.4%;

		&--after {
			right: 16.18%;
			top: 57.1%;
			left: auto;
		}
	}
}

.xpander-360 {
	.card-product__wheel {
		left: 18.3%;
		top: 54.9%;
		width: 12.8%;

		&--after {
			right: 18.3%;
			top: 54.6%;
			left: auto;
		}
	}
}

.outlander-360 {
	.card-product__wheel {
		left: 15.4%;
		top: 54.9%;
		width: 14.2%;

		&--after {
			right: 16.5%;
			left: auto;
		}
	}
}

.triton-2 {
	.card-product__wheel {
		left: 16%;
		top: 53.7%;
		width: 12.9%;

		&--after {
			left: auto;
			right: 23%;
			top: 53.4%;
		}
	}
}
.xforce,
.mm {
	.card-product__wheel {
		left: 16.4%;
		top: 55.6%;
		width: 14.2%;

		&--after {
			left: auto;
			right: 15.2%;
			top: 54.4%;
		}
	}
}
.xpander-cross {
	.card-product__wheel {
		left: 18.5%;
		top: 55.9%;
		width: 11.99%;

		&--after {
			left: auto;
			right: 18.8%;
			top: 55.2%;
		}
	}
}
.all-new-triton-360 {
	.card-product__wheel {
		left: 13.6%;
		top: 57.9%;
		width: 12.7%;

		&--after {
			left: auto;
			right: 19.5%;
			top: 57.9%;
		}
	}
}
