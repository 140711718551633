.news-card {
	position: relative;
	padding: 4rem 0;

	@include minW(768) {
		border-bottom: 0;
		padding-top: 0;
	}

	@include hover {
		.news-card {
			&__link {
				color: $white-color;
				background-color: $black-color;

				&::before {
					opacity: 1;
				}
			}
		}
	}

	&--top {
		border-top: 1px solid #d8d8d8;
		border-bottom: 1px solid #d8d8d8;

		@include minW(768) {
			border-top: 0;
			border-bottom: 0;
		}
	}

	&--horizontal {
		padding-top: 0;

		@include hover {
			.news-card {
				&__link {
					color: $black-color;
				}

				&__body {
					background-color: #f6f6f6;
				}

				&__date {
					color: #5a646e;
				}
			}
		}

		.news-card {
			&__link {
				min-height: 40rem;
				color: $white-color;

				@include minW(992) {
					flex-direction: row-reverse;
					flex-wrap: wrap;
				}
			}

			&__body {
				padding: 5rem 2rem;
				background-color: $black-color;

				@include minW(992) {
					width: 50%;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					transition: background-color 0.3s ease;
					padding: 4rem 3rem;
				}
			}

			&__thumb {
				width: 100%;
				padding-top: 100%;
				position: relative;
				overflow: hidden;
				@include minW(992) {
					width: 50%;
					padding-top: 0;
				}
			}

			&__date {
				color: $white-color;
				transition: color 0.3s ease;
			}
		}
	}

	&--image {
		padding: 0;
		border-bottom: 0;

		@include minW(768) {
			padding-bottom: 4rem;
		}

		.news-card {
			&__link {
				color: $white-color;
				min-height: 40rem;

				&::before {
					opacity: 0.9;
				}
			}

			&__thumb {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				overflow: hidden;
			}

			&__body {
				padding: 5rem 2rem;
			}

			&__date {
				color: $white-color;
			}
		}
	}

	&__link {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		border-bottom: none;
		position: relative;

		@include minW(992) {
			min-height: 28rem;
			background-color: #f6f6f6;
			transition: background-color 0.3s ease;
		}

		&::before {
			content: '';
			opacity: 0;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			background: linear-gradient(
				180deg,
				transparent 0,
				rgba(0, 0, 0, 0.85) 84%,
				rgba(0, 0, 0, 0.85)
			);
			transition: opacity 0.3s ease;
			z-index: 2;
		}
	}

	&__thumb {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		img {
			position: absolute;
			min-width: 100%;
			min-height: 100%;
			display: block;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			object-fit: cover;
		}
	}

	&__body {
		position: relative;
		z-index: 2;

		@include minW(992) {
			padding: 4rem 3rem;
		}
	}

	&__date {
		text-transform: uppercase;
		color: #5a646e;
		margin-bottom: 2rem;
	}

	&__desc {
		margin-bottom: 0;
		font-size: 2rem;
		transition: color 0.3s ease;
		line-height: 1.2;
	}
}
