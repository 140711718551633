.bullet {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	&__icon {
		display: block;
		width: 1.4rem;
		height: 1.4rem;
		border-radius: 50%;
		margin: 0 5px;
		box-shadow: 0px 0px 16px rgba($black-color, 0.3);
		cursor: pointer;
		transition: all 0.3s ease-out;
		.bullet__title {
			opacity: 0;
			text-align: center;
			position: absolute;
			top: 2.2rem;
			left: 0;
			right: 0;
			display: block;
		}
		&.is-active {
			pointer-events: none;
			cursor: default;
			width: 1.8rem;
			height: 1.8rem;
			// transform: scale(1.3);
			.bullet__title {
				opacity: 1;
				// transform: scale(0.9);
			}
		}
	}
}
