.table-tabbed {
	position: relative;
	overflow: hidden;
	padding: 4rem 0;

	&__main {
		padding: 0 2rem;

		@include minW(768) {
			width: 91.6667%;
			margin-left: 8.3333%;
			background-image: linear-gradient(90deg, #fff 45.4%, #f6f6f6 0);
		}
	}

	&__body {
		padding: 4rem 0;

		.taber {
			&__select {
				position: relative;
				display: block;
				max-width: 23rem;
				z-index: 2;

				@include minW(768) {
					display: none;
				}
			}

			&__menu {
				display: none;
				margin-bottom: 4rem;

				@include minW(768) {
					display: flex;
				}
			}

			&__thumb {
				font-size: 1rem;
				background-color: $grey-color;
				margin: -6rem -2rem 4rem;
				padding: 4rem 2rem;

				@include minW(768) {
					margin: 0;
					padding: 0;
					background-color: transparent;
				}
			}

			&__content {
				ul {
					padding-left: 1.8rem;
				}

				.row {
					flex-direction: column-reverse;

					@include minW(768) {
						flex-direction: row;
					}
				}
			}
		}
	}

	&__content {
		position: relative;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.3s ease-out;

		&.is-show {
			max-height: 100000rem;
		}
	}

	&__text {
		padding: 4rem 0;
	}
}
