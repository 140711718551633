.disclaimers {
	.collapse {
		&__item {
			border-bottom: 1px solid #a3a9ad;
		}

		&__head {
			border-bottom: 0;
			p {
				color: $dark-grey-color;
			}
		}

		&__title {
			text-transform: none;
			font-size: 2.1rem;
		}

		&__body {
			color: $dark-grey-color;
		}
	}
}
