.d-none {
  display: none !important;
}

@include minW(576) {
  .d-sm-none {
    display: none !important;
  }
}

@include minW(768) {
  .d-md-none {
    display: none !important;
  }
}

@include minW(992) {
  .d-lg-none {
    display: none !important;
  }
}

@include minW(1200) {
  .d-xl-none {
    display: none !important;
  }
}

@include minW(1400) {
  .d-xxl-none {
    display: none !important;
  }
}
