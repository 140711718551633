.register-one {
	position: relative;
	padding: 3rem 0;
	min-height: 100vh;

	&.is-blur {
		opacity: 0.6;
		pointer-events: none;
		cursor: default;
	}

	&__title {
		text-align: center;
	}

	&__body {

		.title {
			margin-bottom: 6rem;
			text-align: center;
		}
	}

	.form {
		&__inline {
			display: flex;
			align-items: flex-end;
		}

		&__control {
			margin-right: 1rem;
		}
	}
}


.register-two {
	position: relative;
	padding: 3rem 0;
	min-height: 100vh;

	&.is-blur {
		opacity: 0.6;
		pointer-events: none;
		cursor: default;
	}

	&__title {
		text-align: center;
	}
}
