.book-a-service {
	position: relative;
	overflow: hidden;
	padding: 8rem 0;

	&__title {
		text-align: center;
		margin-bottom: 6rem;
	}

	.btn {
		width: 100%;
	}
}

.book-a-service-confirm {
	position: relative;
	padding: 9rem 0;
	overflow: hidden;
	display: none;

	&__item {
		font-size: 1.6rem;
		line-height: 2.4rem;
		margin-bottom: 2rem;

		@include minW(992) {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__label {
		color: $dark-grey-color;
		text-transform: uppercase;

		@include minW(992) {
			width: 30%;
		}
	}

	&__value {
		color: $black-color;

		@include minW(992) {
			width: 70%;
		}
	}

	&__button {
		display: flex;
		flex-direction: column-reverse;

		@include minW(992) {
			padding-left: 30%;
			display: block;
		}

		.btn {
			width: 100%;
			margin-bottom: 2rem;

			@include minW(992) {
				width: auto;
				margin-bottom: 0;
			}
		}
	}

	&__error {
		color: $red-color;
	}

	.title {
		text-align: center;
	}
}

.book-a-service-thank {
	display: none;
	padding: 9rem 0;
}

.cta-row--service {
	display: none;
}
