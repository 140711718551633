.car-selector {
	position: relative;
	width: 100%;
	padding: 4rem 0;
	overflow: hidden;
	text-align: center;

	.slick {
		margin-bottom: 3rem;

		@include minW(768) {
			margin-bottom: 4rem;
		}

		&-slide {
			opacity: 0.5;
			transition: opacity 0.3s ease-out;
			padding: 0 1rem;

			@include minW(768) {
				padding: 0 2rem;
			}
		}

		&-active {
			opacity: 1;

			.car-selector {
				&__body {
					opacity: 1;
				}
			}

			@include hover {
				.car-selector {
					&__bg {
						&::after {
							animation: m;
							animation-fill-mode: backwards;
							animation-duration: .3s;
							animation-delay: .15s;
							transform: skewX(0deg) translateX(-100%);
						}
					}

					&__inner {
						max-width: 100%;
						opacity: 1;
					}

					&__body {

						.title,
						.body-copy {
							color: $white-color;
						}
					}
				}
			}
		}
	}

	.btn {
		margin: 0 15px;
		width: calc(100% - 30px);

		@include minW(768) {
			width: auto;
		}
	}

	&__item {
		position: relative;
	}

	&__bg {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		overflow: hidden;
		width: 65%;
		backface-visibility: hidden;
		display: none;
		flex-direction: column;
		flex: 1 1 auto;
		height: 100%;
		z-index: 1;

		@include minW(992) {
			display: flex;
		}

		&::after {
			content: '';
			width: 159%;
			height: 100%;
			position: absolute;
			background-color: transparent;
			left: 0;
			top: 0;
			transform-origin: bottom right;
			animation: n;
			animation-duration: .3s;
		}
	}

	&__inner {
		background-repeat: no-repeat;
		transition: opacity .15s ease-out .45s;
		opacity: 0;
		background-color: #5a646e;
		background-position: 50%;
		background-size: cover;
		flex: 1 1 auto;
		position: relative;
		transition-delay: 0ms;

		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: linear-gradient(180deg, transparent 50%, rgba(0, 0, 0, .54) 70%);
		}
	}

	&__main {
		position: relative;
		padding: 0;
		text-align: center;
		display: block;
		z-index: 2;

		@include minW(768) {
			padding: 9rem 0 3rem;
		}
	}

	&__body {
		opacity: 0;
		color: $black-color;

		.title {
			text-transform: uppercase;
			margin-bottom: 5px;
		}
	}
}
