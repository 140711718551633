.innovation {
	.heritage-image__thumb {
		margin-bottom: 3rem;
	}
	.mfourthree {
		padding: 4rem 0;
	}
	.heritage-image__content {
		margin-bottom: 6rem;
	}
	.btn-explore {
		display: flex;
		margin-top: 8rem;
		i {
			margin-right: 1rem;
		}
	}
}
