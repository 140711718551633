.history {
	position: relative;
	overflow: hidden;
	padding-bottom: 3rem;

	@include minW(768) {
		padding-bottom: 3rem;
	}

	&--expand {
		.history {
			&__main {
				max-width: 164rem;
			}
		}
	}

	&__main {
		position: relative;
		width: 100%;
		max-width: 90rem;
		padding: 0 2rem;
		margin: 0 auto;
		@include minW(1024) {
			padding: 0 8.3%;
		}
	}

	&__head {
		text-align: center;
		margin-bottom: 5.2rem;
	}

	&__title {
		margin-bottom: 5rem;
	}

	&__box {
		position: relative;
		flex-wrap: wrap;

		@include minW(768) {
			display: flex;
			padding: 5rem 0;
		}

		&:first-child {
			@include minW(768) {
				padding-top: 10rem;
			}
		}

		&:last-child {
			@include minW(768) {
				padding-bottom: 0rem;
			}
		}

		&::before {
			content: '';
			display: none;
			width: 1px;
			height: 100%;
			background-color: $light-grey-color;
			position: absolute;
			top: 0;

			@include minW(768) {
				left: 50%;
				display: block;
			}
		}
	}

	&__item {
		position: relative;
		border-left: 0;
		margin-bottom: 2rem;

		@include minW(768) {
			width: 50%;
			padding-left: 7rem;
			padding-top: 10rem;
			margin-bottom: 0;
		}

		&:nth-child(2n + 1) {
			border-left: 1px solid $light-grey-color;
			padding-top: 5rem;
			padding-bottom: 5rem;
			padding-left: 5rem;
			margin-bottom: 0;

			@include minW(768) {
				border-left: 0;
				padding-left: 0;
				padding-top: 0;
				padding-right: 7rem;
				text-align: right;
			}

			&::before {
				display: block;

				@include minW(768) {
					left: auto;
					right: 0;
				}
			}
		}

		&::before {
			content: '';
			display: none;
			width: 3.8rem;
			height: 1px;
			background-color: $light-grey-color;
			position: absolute;
			left: 0;

			@include minW(768) {
				display: block;
				width: 4.8rem;
			}
		}

		.title {
			/* margin-top: 2rem; */
		}
	}

	&__content {
		font-size: 1.8rem;
		line-height: 2.7rem;
		color: $dark-grey-color;

		@include minW(768) {
			font-size: 2.1rem;
			line-height: 3.6rem;
		}
	}

	&__month {
		font-size: 1.4rem;
		line-height: 1.8rem;
		margin-bottom: 1rem;
	}

	&__text {
		text-align: center;
		padding: 10rem 0;
		font-size: 2.4rem;
		line-height: 3.8rem;
		color: $dark-grey-color;

		@include minW(768) {
			font-size: 3.6rem;
			line-height: 6rem;
		}

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__foot {
		text-align: center;
		padding: 10rem 0;
	}
}
