.promotion-card {
	position: relative;
	margin-bottom: 4rem;

	&__thumb {
		margin-bottom: 2rem;

		@include minW(992) {
			margin-bottom: 0;
		}
	}

	&__content {
		margin-bottom: 2rem;

		.label {
			margin-bottom: 1rem;
		}
	}
}

.btn-group {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -1rem;
	width: 100%;
	justify-content: center;

	@include minW(992) {
		margin: 0 -2rem;
		width: auto;
	}

	&__item {
		display: flex;
		padding: 0 1rem;
		width: 50%;
		margin-bottom: 3rem;

		&:first-child {
			width: 100%;

			@include minW(992) {
				width: auto;
			}
		}

		&:first-child+&:last-child {
			width: 100%;

			@include minW(992) {
				width: auto;
			}
		}

		@include minW(992) {
			display: inline-flex;
			padding: 0 2rem;
			margin-bottom: 2rem;
			width: auto;
		}

		.btn {
			display: flex;
			width: 100%;

			@include minW(992) {
				width: auto;
			}
		}
	}
}
