.discovery {
	position: relative;
	overflow: hidden;
	margin-top: 4rem;

	@include minW(768) {
		padding: 4rem 0;
		margin-bottom: 4rem;
	}

	&--reverse {
		.discovery {
			&__main {
				@include minW(768) {
					margin-left: 8.33333333%;
					padding-left: 2rem;
					padding-right: 0;
				}
			}

			&__body {
				@include minW(768) {
					right: calc(8.33333333% + 2rem);
					left: auto;
				}
			}
		}
	}

	&__main {
		width: 100%;

		@include minW(768) {
			max-width: 91.66666667%;
			padding-right: 2rem;
		}
	}

	&__body {
		position: relative;
		padding: 2rem;
		background-color: $black-color;
		margin: 0 2rem;
		z-index: 2;

		@include minW(768) {
			margin: 0;
			position: absolute;
			top: 0;
			left: calc(8.33333333% + 2rem);
			padding: 3rem 4rem 4rem;
			max-width: 36rem;
		}
	}

	&__content {
		color: $white-color;

		.label-small {
			margin-bottom: 8rem;
		}
	}

	&__title {
		text-transform: uppercase;
	}

	&__thumb {
		position: relative;
		width: 100%;
		min-height: 40rem;
		overflow: hidden;
		margin-top: -12rem;

		@include minW(768) {
			min-height: 80rem;
			margin-top: 0;
		}

		.img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
