.table-steps {
	position: relative;
	overflow: hidden;
	padding: 4rem 0;

	&__item {
		position: relative;
		margin-bottom: 3.5rem;
	}

	&__number {
		color: #5a646e;
		border-bottom: 1px solid #979797;
		margin-bottom: 2rem;
	}
}
