.heritage-explore {
	position: relative;
	overflow: hidden;
	padding: 12.4rem 0;

	&__foot {
		text-align: center;
	}
}

.heritage-image {
	position: relative;
	overflow: hidden;
	padding: 21rem 0 8rem;

	&__head {
		margin-bottom: -5rem;
	}

	&__title {
		position: relative;
		margin: 0 2rem;
		z-index: 2;

		@include minW(768) {
			max-width: 90rem;
		}
	}

	&__thumb {
		width: 100%;
		margin-bottom: 12rem;

		@include minW(768) {
			max-width: 91.66666667%;
			padding-right: 2rem;
		}
	}
}


.years-menu {
	position: relative;
	background-color: #fff;
	width: 100%;
	margin-bottom: 5rem;
	z-index: 9;

	&.posFix {
		position: fixed;
		top: 0;

		&.scroll-up {
			top: 14.6rem;
		}
	}

	&.posAbs {
		position: absolute;
	}

	&__body {
		position: relative;
		display: flex;
		align-items: center;
		box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.31);
		overflow-x: auto;

		@include minW(992) {
			justify-content: center;
		}
	}

	&__item {
		padding: 1rem 0;
		margin: 0 2.5rem;
		font-size: 1.4rem;
		min-width: 10rem;
		text-align: center;

		&.is-active {
			font-weight: 700;
			font-size: 2rem;
			border-top: 2px solid $red-color;
			min-width: 14rem;

			@include minW(992) {
				font-size: 2.4rem;
			}
		}
	}

	&__text {
		cursor: pointer;
	}
}
