.key-figures {
	font-size: 2.4rem;
	line-height: 3.8rem;
	color: $black-color;
}

.quote-copy {
	font-size: 2.4rem;
	line-height: 3.8rem;

	@include minW(768) {
		font-size: 3.6rem;
		line-height: 6rem;
	}

	&--dark-grey {
		color: $dark-grey-color;
	}
}

.intro-copy {
	font-size: 1.8rem;
	line-height: 3rem;

	@include minW(768) {
		font-size: 2.1rem;
		line-height: 3.6rem;
	}

	&--dark-grey {
		color: $dark-grey-color;
	}
}

.body-copy {
	font-size: 1.6rem;
	line-height: 2.8rem;

	&--dark-grey {
		color: $dark-grey-color;
	}
}

.small-type {
	font-size: 1.4rem;
	line-height: 2.4rem;

	&--dark-grey {
		color: $dark-grey-color;
	}
}

.tags {
	font-size: 1.2rem;
	line-height: 1.8rem;
	font-weight: 700;
}

.label-big {
	font-size: 1.4rem;
	line-height: 1.8rem;
	letter-spacing: 2px;
	font-weight: 400;

	&--dark-grey {
		color: $dark-grey-color;
	}
}

.label-medium {
	font-size: 1.2rem;
	line-height: 1.6rem;
	letter-spacing: 2;
	font-weight: 500;

	&--dark-grey {
		color: $dark-grey-color;
	}
}

.label-small {
	font-size: 1.2rem;
	line-height: 1.6rem;
	letter-spacing: 2px;
	text-transform: uppercase;
	font-weight: 400;


	&--dark-grey {
		color: $dark-grey-color;
	}
}

.label {
	font-size: 1.2rem;
	line-height: 1.6rem;
	letter-spacing: 2px;
	text-transform: uppercase;
	font-weight: 400;

	&--big {
		font-size: 1.4rem;
		line-height: 1.8rem;
	}

	&--500 {
		font-weight: 500;
	}

	&--700 {
		font-weight: 700;
	}

	&--uppercase {
		text-transform: uppercase;
	}

	&--black {
		color: $black-color;
	}

	&--dark-grey {
		color: $dark-grey-color;
	}
}
