.warranty {
  position: relative;
  overflow: hidden;
  padding: 10rem 0;

  &__head {
    padding: 18rem 0 8rem;
    background-color: $black-color;
    color: $white-color;
    text-align: center;
    margin-bottom: 8rem;
  }
}
