.img--full {
	width: 100% !important;
	height: 100% !important;
	object-fit: cover;
}

@media only screen and (max-width: 420px) {
	.standard-hero__logo {
		max-height: 112px;
	}

	.standard-hero__logo img {
		max-height: 112px;
	}
}
