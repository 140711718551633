.spec {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 2px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 4rem;

  @include minW(768) {
    flex-wrap: nowrap;
  }

  &__item {
    padding-left: 4.8rem;
    padding-right: 2.4rem;
    position: relative;
    width: 50%;
    margin-bottom: 2rem;

    @include minW(768) {
      width: auto;
      margin-bottom: 0;
    }
  }

  &__icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4rem;

    .img {
      width: 100%;
    }
  }
}
