.loading {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#000, 0.75);
	display: none;
	z-index: 999;

	&__icon {
		width: 64px;
		height: 64px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			border: 3px solid transparent;
			border-radius: 50%;
			border-top-color: #2bb98b;
			display: inline-block;
			animation: spin 650ms ease infinite;
		}
	}
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}
