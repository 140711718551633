.filter-session {
	position: relative;
	display: flex;
	font-size: 1.4rem;
	line-height: 1.8rem;
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-bottom: 3rem;

	&__item {
		position: relative;
		width: 33.3333%;
		text-align: center;
		padding: 1.2rem;
		cursor: pointer;

		&::after {
			content: '';
			display: block;
			width: 0;
			height: 2px;
			background-color: $red-color;
			position: absolute;
			bottom: 0;
			left: 0;
		}

		&.is-active {
			&::after {
				width: 100%;
			}
		}
	}
}

.card-time {
	border: 1px solid $grey-color;
	margin-bottom: 3rem;
	position: relative;
	overflow: hidden;
	overflow-y: auto;
	max-height: calc(100vh - 33.8rem);
	min-height: 20rem;

	@include minW(768) {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		padding: 1.6rem;
		border: 1px solid $grey-color;
		cursor: pointer;
		font-size: 1.4rem;
		line-height: 2.4rem;
		font-weight: 700;

		@include minW(768) {
			width: 25%;
			padding: 3rem;
		}

		@include hover {
			background-color: $grey-color;
		}

		&.is-disabled {
			background-color: $grey-color;
			color: $cold-grey-color;
			pointer-events: none;
			cursor: default;

			.icon {
				opacity: 0.5;
			}
		}

		&.is-checked {
			background-color: $red-color !important;
			color: $white-color !important;
			pointer-events: none;
			cursor: default;

			.icon {
				&--checked-circle {
					background-image: url('../img/icons/checked-circle-white.svg');
				}

				&--gift-circle {
					background-image: url('../img/icons/gift-circle-white.svg');
				}
			}
		}
	}

	&__body {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;

		@include minW(768) {
			flex-direction: column;
		}
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;

		.icon {
			display: block;
			margin: 0 3px;
		}
	}

	&__nodata {
		text-align: center;
		margin: 0;
		padding: 2rem;
		width: 100%;
	}
}

.filter-status {
	display: flex;
	margin-bottom: 3rem;

	&__item {
		display: flex;
		align-items: center;
		margin-left: 1rem;
		cursor: pointer;
		color: $cold-grey-color;

		&:first-child {
			margin-left: 0;
		}

		&.is-active {
			color: $black-color;
		}

		.icon {
			margin-right: 1rem;
		}
	}
}
