.text-center {
  text-align: center;
}

.about-us-slider {
  position: relative;
  overflow: hidden;
  padding-right: 5rem;
  margin-bottom: 12.4rem;

  &__paging {
    color: $dark-grey-color;
    font-size: 1.2rem;

    @include minW(768) {
      text-align: right;
    }

    span {
      color: $black-color;
      font-weight: 700;
      margin: 0 5px;
    }
  }

  .slick {
    &-list {
      overflow: unset;
      margin: 0 -2rem;
    }

    &-slide {
      padding: 0 2rem;
    }
  }

}
