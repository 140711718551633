.brand-panel {
  position: relative;
  overflow: hidden;
  padding: 4rem 0;

  &__main {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    width: 100%;
    min-height: 40rem;

    @include minW(992) {
      width: 33.3333%;
      min-height: 90rem;
    }

    @include hover {
      .card {
        @include minW(992) {
          left: -4rem;
          right: -4rem;
          width: calc(100% + 8rem);
          z-index: 1;
        }

        &__thumb {
          transform: scale(1.1);
        }

        &__body {
          transform: translateY(-5rem);

          @include minW(992) {
            transform: translateY(-10rem);
          }
        }
      }
    }

    .card {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all .2s ease-out;
      overflow: hidden;
      z-index: 0;

      &__thumb {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: transform 0.2s;

        .img {
          width: auto;
          max-width: none;
          min-width: 100%;
          min-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &__body {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        color: $white-color;
        padding: 2rem 2rem 9rem;
        transition: transform 0.3s ease-out;

        @include minW(992) {
          padding: 0 0 9rem;
        }
      }

      &__content {
        @include minW(992) {
          width: 24.3333vw;
          margin: 0 auto;
        }
      }

      &__title {
        font-size: 3.6rem;
      }
    }
  }
}
