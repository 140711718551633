.spare-part-product {
	position: relative;
	overflow: hidden;
	padding-top: 5rem;
	padding-bottom: 5rem;

	@include minW(992) {
		padding-top: 6rem;
		padding-bottom: 0;
	}

	&__item {
		margin-bottom: 4rem;

		@include minW(992) {
			margin-bottom: 10rem;
		}
	}

	&__thumb {
		margin-bottom: 2rem;
	}

	&__content {
		color: $dark-grey-color;
	}
}
