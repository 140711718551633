.careers {
  position: relative;
  overflow: hidden;

  &__head {
    margin: 23rem 0 13rem;
    text-align: center;
  }

  &__body {
    max-width: 132rem;
    padding: 8rem 2rem;
    margin: 0 auto;
  }

  &__location {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $dark-grey-color;
    text-transform: uppercase;

    @include minW(768) {
      text-align: center;
    }
  }

  &__content {
    margin-bottom: 9rem;
  }
}
