.image-mobile-app {
	margin-top: 14rem;

	@media (min-width: 992px) {
	}
}
.qr-content {
	display: flex;
	align-center: center;
	justify-content: center;

	.qr,
	.qr-note {
		text-align: center;
	}
	img {
		width: 60%;
		/* border: 1px solid #000; */
		margin: 2rem auto;
		@media (min-width: 992px) {
			width: 40%;
		}
	}
}
.qr h4 {
	&.mb-only {
		@media (min-width: 992px) {
			display: none;
		}
	}
	@media (max-width: 992px) {
		display: none;
		&.mb-only {
			display: block;
		}
	}
}
.qr-note {
	text-align: center;
	margin-top: 3rem;
	@media (max-width: 992px) {
		display: none;
	}
}
.qr-only {
	.fifty-fifty__content {
		background: rgba(197, 199, 201, 0.21);
		margin-bottom: 8rem;
		@media (max-width: 991px) {
			/* padding: 8vw 0; */
			background: transparent;
		}
	}
	.qr-android {
		@media (min-width: 992px) {
			border-right: 1px dashed #a3a9ad;
		}
	}
}
#mb-app {
	.fifty-fifty__body {
		padding: 0;
		width: 100%;
		margin: 4rem auto;
		@media (min-width: 992px) {
			width: 40%;
			margin: 0 auto;
		}
	}
	.title--4 {
		text-align: center;
		@media (max-width: 992px) {
			display: none;
		}
	}
	@media (min-width: 992px) {
		border-top: 1px solid #fff;
	}

	.fifty-fifty__thumb {
		background: #000;
	}

	.editor-car__content h2 {
		text-transform: uppercase;
		text-align: center;
		margin-top: 3rem;
	}

	.row {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.editor-car__thumb img {
		box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
		border-radius: 20%;
		max-width: 80%;
		margin: 0 auto;

		@media (min-width: 992px) {
			max-width: 44%;
		}

		/* margin: 0 auto; */
	}

	.nav-tabs {
		display: flex;
		justify-content: center;
		align-items: center;
		letter-spacing: 0;
		outline: none;
		text-decoration: none;
		text-transform: uppercase;
		font-weight: 700;

		@media (min-width: 992px) {
			font-size: 1.2rem;

			letter-spacing: 0.2em;
		}

		.nav {
			margin: 0 1rem;
			-webkit-transition: all 0.2s ease-in-out;
			-moz-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			cursor: pointer;

			&.current {
				text-decoration: underline;
			}
		}
	}

	.tab {
		position: relative;
		overflow: hidden;
		-webkit-font-smoothing: antialiased;
	}

	.tabs {
		display: table;
		position: relative;
		overflow: hidden;
		margin: 0;
		width: 100%;

		li {
			overflow: hidden;
			padding: 0;
			position: relative;
		}
	}

	.tabs_item {
		display: none;
		text-align: center;

		img {
			/* width: 60%; */
			max-width: 50%;
			margin: 1rem auto;

			@media (min-width: 992px) {
				max-width: 26%;
			}
		}

		&:first-child {
			display: block;
		}
	}
}

.title-header {
	display: flex;
	justify-content: center;

	p {
		margin-bottom: 0;
	}

	ul {
		/* font-size: 0.7rem; */
	}
}

.mb-app {
	.hero {
		padding-top: 0;

		@media (min-width: 992px) {
			padding-top: 0;
		}
	}

	.image-block {
		@media (max-width: 992px) {
			padding: 4rem 0;
			background: #000;
		}
	}

	.fifty-fifty__thumb .img {
		object-fit: contain;
		background: #000;
	}
}
