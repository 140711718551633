.download-brochure {
	.hero--top {
		@include minW(992) {
			padding-top: 18.6rem;
			padding-bottom: 3.5rem;
		}
	}
	.row {
		padding: 2rem 1rem;
		@include minW(992) {
			padding: 4rem 2rem;
		}
		justify-content: center;
		display: flex;
		box-sizing: border-box;
		.row__col--lg-3 {
			flex-basis: 25%;
			max-width: 25%;
		}
	}
	.card {
		h2 {
			text-align: center;
		}
		&__wrapper__image {
			background-color: #f6f6f6;
			margin-bottom: 5rem;
			padding-top: 4rem;
			/* display: flex;
			justify-content: center; */
			text-align: center;
			img {
				max-width: 75%;
				width: 100%;
				height: auto;
				margin-bottom: -2.5rem;
				box-shadow: 5px 5px 32px -3px rgb(0 0 0 / 36%);
			}
		}
		&__wrapper__select {
			text-align: center;
			.btn--link {
				i {
					margin-right: 1rem;
				}
				&:after {
					content: none;
				}
			}
		}
	}
}
