.news-tags {
	position: relative;

	&__body {

		strong {
			position: relative;
			margin-right: 1rem;
			padding-left: 2.5rem;

			&::before {
				display: block;
				content: '';
				width: 2rem;
				height: 2rem;
				background: url('../img/icons/tag.png') no-repeat center;
				background-size: cover;
				margin-right: 5px;
				position: absolute;
				left: 0;
				top: 1px;
			}
		}

		a {
			@include hover {
				color: $red-color;
			}
		}
	}
}

.news-more {
	position: relative;

	&__list {
		list-style: disc;
	}


	&__date {
		display: inline-block;
		margin-right: 1rem;
	}

	&__title {
		font-weight: 500;

		@include hover {
			color: $red-color;
		}
	}
}

.filter-table {
	input {
		border: 0;
		border-bottom: 1px solid $grey-color;
		margin-left: 1rem;
		min-width: 25rem;

		&:focus {
			outline: 0;
			border-color: $dark-grey-color;
		}
	}
}
