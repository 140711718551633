$primary-font: 'MMC', Arial, Helvetica, Verdana, sans-serif !default;

$white-color: #fff !default;
$black-color: #000 !default;
$red-color: #ed0000 !default;
$grey-color: #e3e5e6 !default;
// $dark-grey-color: #5a646e !default;
// $cold-grey-color: #a3a9ad !default;
// $light-grey-color: #c8c8c8 !default;

$dark-grey-color: #686d71 !default;
$cold-grey-color: #bfc2c4 !default;
$light-grey-color: #e3e5e6 !default;
