.car-choose {
	position: relative;
	margin: 0 -2rem;
	padding: 4rem 0;
	overflow: hidden;
	text-align: center;

	.title {
		margin-bottom: 9rem;
	}

	.slick {
		margin-bottom: 3rem;

		@include minW(768) {
			margin-bottom: 4rem;
		}

		&-slide {
			opacity: 0.5;
			transition: opacity 0.3s ease-out;
			padding: 0 1rem;

			@include minW(768) {
				padding: 0 2rem;
			}
		}

		&-active {
			opacity: 1;

			.car-choose {
				&__item {
					cursor: pointer;
				}

				&__body {
					opacity: 1;
				}
			}
		}

		&-center {
			.car-choose {
				&__bg {
					&::after {
						animation: m;
						animation-fill-mode: backwards;
						animation-duration: .3s;
						animation-delay: .15s;
						transform: skewX(0deg) translateX(-100%);
					}
				}

				&__inner {
					max-width: 100%;
					opacity: 1;
				}


			}

			&.is-selected {
				.car-choose {
					&__selected {
						opacity: 1;
					}
				}
			}
		}
	}

	&__item {
		position: relative;
	}

	&__bg {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		overflow: hidden;
		width: 65%;
		backface-visibility: hidden;
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		height: 100%;
		z-index: 1;

		&::after {
			content: '';
			width: 159%;
			height: 100%;
			position: absolute;
			background-color: transparent;
			left: 0;
			top: 0;
			transform-origin: bottom right;
			animation: n;
			animation-duration: .3s;
		}
	}

	&__inner {
		background-repeat: no-repeat;
		transition: opacity .15s ease-out .45s;
		opacity: 0;
		background-color: $grey-color;
		background-position: 50%;
		background-size: cover;
		flex: 1 1 auto;
		position: relative;
		transition-delay: 0ms;
	}

	&__main {
		position: relative;
		padding: 0;
		text-align: center;
		display: block;
		padding-bottom: 3rem;
		z-index: 2;
	}

	&__selected {
		font-size: 1.2rem;
		line-height: 1.2rem;
		letter-spacing: 2px;
		color: $white-color;
		background-color: $red-color;
		padding: 6px 2rem;
		display: inline-block;
		border-radius: 4px;
		opacity: 0;
		transition: opacity 0.3s ease-out;
		margin: 3.2rem 0;

		@include minW(768) {
			margin: 6.4rem 0;
		}
	}

	&__body {
		opacity: 0;
		color: $black-color;

		.title {
			text-transform: uppercase;
			margin-bottom: 5px;
		}
	}
}
