.spare-part {
	position: relative;
	overflow: hidden;
	padding-top: 18rem;

	&__item {
		margin-bottom: 15rem;

		.title {
			margin-bottom: 10rem;
		}

		.btn {
			width: 100%;

			@include minW(992) {
				width: auto;
			}
		}
	}
}
