.agree-cookie {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	box-shadow: 0 5px 1rem $dark-grey-color;
	padding: 2rem 0;
	z-index: 97;
	display: none;

	&__body {
		position: relative;
		@media (min-width: 768px) {
			padding-right: 17rem;
		}
		font-size: 1.4rem;
		color: $dark-grey-color;

		p:last-child {
			margin-bottom: 0;
		}
	}

	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 1rem 0 4rem;
		@media (min-width: 768px) {
			justify-content: flex-end;
			margin: 0;
		}
		height: 100%;
	}
}
