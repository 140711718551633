.finance-calculator {
	position: relative;

	&__step {
		position: relative;
		overflow: hidden;
		height: 0;

		&--one {
			height: auto;
		}
	}
}
.financial-slider {
	.standard-hero__bg::before {
		content: none;
	}
}
.loan-term {
	padding: 8rem 0;
}
