.loan-product {
	position: relative;
	overflow: hidden;
	padding: 4rem 0;

	&__head {
		margin-bottom: 5rem;
	}

	&__foot {
		text-align: center;
	}
}

.loan-product-card {
	position: relative;
	margin-bottom: 6rem;
	display: block;

	&__thumb {
		margin-bottom: 3.6rem;
	}

	&__fixed {
		color: $red-color;
		font-size: 2rem;
		line-height: 4.3rem;
		font-weight: 500;
	}
}
