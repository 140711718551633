.nav-dropdown {
	font-size: 1.2rem;
	line-height: 1.2rem;
	text-transform: uppercase;
	text-align: right;

	&.is-open {
		.nav-dropdown {
			&__current {
				&::after {
					transform: rotate(135deg);
					top: 5px;
				}
			}
		}
	}

	&__current {
		padding-right: 4rem;
		position: relative;
		cursor: pointer;

		&::after {
			content: '';
			display: block;
			width: 8px;
			height: 8px;
			border-left: 2px solid $red-color;
			border-bottom: 2px solid $red-color;
			transform: rotate(-45deg);
			position: absolute;
			right: 2.4rem;
			top: -1px;
		}
	}

	&__body {
		overflow: hidden;
		position: absolute;
		top: 2.8rem;
		right: 0;
		width: 100%;
		background-color: $black-color;
		height: 0;
	}

	&__menu {
		padding: 2rem 0;
	}

	&__item {
		padding: 1.5rem 0;

		&.is-active {
			.nav-dropdown {
				&__link {
					color: $red-color;
					border-right: 2px solid $red-color;
				}
			}
		}
	}

	&__link {
		color: $white-color;
		padding: 5px 2rem;
		display: block;
		text-align: right;
	}
}
