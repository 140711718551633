.cta-row {
	position: relative;
	overflow: hidden;
	padding: 0 0 4rem;

	@include minW(768) {
		padding: 8rem 0;
	}

	&--btn {
		margin-top: 10rem;

		.btn {
			width: 100%;
		}
	}

	&__main {

		@include minW(768) {
			width: 83.3333%;
			margin: 0 8.3333%;
		}
	}

	&__body {
		padding: 0 2rem;
		color: #5a646e;

		.title {
			position: relative;
			text-transform: uppercase;

			&::before {
				content: '';
				display: block;
				width: 8rem;
				height: 1px;
				background-color: #a3a9ad;
				margin-bottom: 2rem;
			}
		}
	}

	&__desc {
		margin-bottom: 4rem;
	}

	&__link {

		@include minW(768) {
			display: flex;
			align-items: center;
		}
	}

	&__item {
		margin-bottom: 2rem;

		@include minW(768) {
			padding-left: 4rem;
			margin-bottom: 0;
		}

		&:first-child {
			@include minW(768) {
				padding-left: 0;
			}
		}
	}
}
