.sitemap {
  position: relative;
  padding: 14rem 0 10rem;
  overflow: hidden;

  &-list {
    list-style: decimal;

    &__link {
      color: $black-color;

      @include hover {
        text-decoration: underline;
      }
    }
  }
}
