.promotions {
	position: relative;
	overflow: hidden;
	padding: 4rem 0;

	&__filter {

		@include minW(992) {
			padding: 0 13rem;
		}
	}

	&__foot {
		text-align: center;
	}
}
