.collapse {
	position: relative;
	margin-bottom: 1.5rem;

	&--standard {
		.collapse {
			&__head {
				padding: 0;
				border-bottom: 0;
				display: flex;
				align-items: center;
				justify-content: center;

				&::after {
					position: relative;
					top: auto;
					right: auto;
					width: 8px;
					height: 8px;
					margin-top: -3px;
					margin-left: 1rem;
				}
			}

			&__title {
				font-size: 1.4rem;
				line-height: 2.8rem;
			}

			&__item {
				&.is-show {
					.collapse {
						&__head {
							&::after {
								top: auto;
								margin-top: 5px;
							}
						}
					}
				}
			}

			&__content {
				padding: 5rem 0;
			}
		}
	}

	&--faqs {
		.collapse {
			&__head {
				padding: 4rem 4rem 4rem 0;
				border-bottom: 0;

				&::after {
					top: 4.5rem;
				}
			}

			&__title {
				font-size: 2rem;
				line-height: 3rem;
				text-transform: none;
			}

			&__item {
				border-bottom: 1px solid #606060;

				&:last-child {
					border-bottom: 0;
				}

				&.is-show {
					border-bottom: 0;

					.collapse {
						&__head {
							&::after {
								top: 5rem;
							}
						}
					}
				}
			}
		}
	}

	&__item {
		border-bottom: 1px solid #fff;

		&:first-child {
			border-top: 1px solid #fff;
		}

		&.is-show {
			.collapse {
				&__head {
					&::after {
						transform: rotate(135deg);
						top: 8rem;
					}
				}
			}
		}
	}

	&__head {
		position: relative;
		padding-right: 3rem;
		padding: 4.6rem 3rem 0 0;
		border-bottom: 1px solid #606060;
		user-select: none;
		cursor: pointer;

		&::after {
			content: '';
			display: block;
			position: absolute;
			right: 3px;
			top: 7rem;
			width: 1rem;
			height: 1rem;
			border-left: 2px solid $black-color;
			border-bottom: 2px solid $black-color;
			transform: rotate(-45deg);
		}

		.title {
			margin-bottom: 0;
			text-transform: uppercase;
			font-weight: 500;
		}
	}

	&__body {
		position: relative;
		overflow: hidden;
		height: 0;
	}

	&__content {
		padding: 2rem 0;
	}

	&__title {
		font-size: 2.5rem;
		line-height: 6.7rem;
		font-weight: 500;
		margin-bottom: 0;
		text-transform: uppercase;
	}
}
