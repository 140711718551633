.checkbox {
	position: relative;
	user-select: none;
	margin-bottom: 2rem;

	input[type='checkbox'] {
		position: absolute;
		opacity: 0;
		z-index: -1;

		&:checked {
			+ span {
				&::after {
					opacity: 1;
				}
			}
		}

		+ span {
			&::before {
				border-radius: 0;
			}

			&::after {
				content: '';
				width: 1.2rem;
				height: 8px;
				border-left: 2px solid $black-color;
				border-bottom: 2px solid $black-color;
				transform: rotate(-45deg);
				opacity: 0;
				top: 1rem;
				left: 5px;
			}
		}
	}

	span {
		position: relative;
		display: inline-flex;
		align-items: center;
		min-height: 2.2rem;
		padding-left: 3.7rem;
		cursor: pointer;
		font-size: 1.6rem;
		font-weight: 700;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 6px;
			left: 0;
			display: block;
			width: 2.2rem;
			height: 2.2rem;
			transition: all 0.3s ease;
		}

		&::before {
			border: 1px solid $light-grey-color;
			background-color: #ffff;
			border-radius: 50%;
		}
		a {
			text-decoration: underline;
		}
	}
}

.radio {
	position: relative;
	user-select: none;
	margin-bottom: 2rem;

	input[type='radio'] {
		position: absolute;
		opacity: 0;
		z-index: -1;

		&:checked {
			+ span {
				&::after {
					opacity: 1;
				}
			}
		}

		+ span {
			&::after {
				content: '';
				width: 1.6rem;
				height: 1.6rem;
				background-color: $black-color;
				opacity: 0;
				top: 8px;
				left: 4px;
			}
		}
	}

	span {
		position: relative;
		display: inline-flex;
		align-items: center;
		min-height: 2.2rem;
		padding-left: 3.7rem;
		cursor: pointer;
		font-size: 1.6rem;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 4px;
			left: 0;
			display: block;
			width: 2.2rem;
			height: 2.2rem;
			border-radius: 50%;
			transition: all 0.3s ease;
		}

		&::before {
			border: 1px solid $light-grey-color;
			background-color: #ffff;
		}
	}
}
