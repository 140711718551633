.carousel-tabbed {
	position: relative;
	overflow: hidden;
	padding: 4rem 0 5rem;

	@include minW(768) {
		padding: 8rem 0 10rem;
	}

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: calc(100% - 15rem);
		position: absolute;
		left: 0;
		top: 15rem;
		background-color: #f6f6f6;

		@include minW(768) {
			height: calc(100% - 28rem);
			top: 28rem;
		}
	}

	&__head {
		margin-bottom: 4rem;
	}

	&__image {
		margin-bottom: 2.5rem;
	}

	&__controls {
		margin-bottom: 3rem;

		.type-text {
			font-size: 2.1rem;
			line-height: 2.5rem;
			font-weight: 700;
			text-transform: uppercase;
			display: none;

			@include minW(768) {
				display: flex;
			}

			&__item {
				position: relative;
				margin-left: 4rem;
				color: #5a646e;
				padding-bottom: 3px;
				cursor: pointer;

				&:first-child {
					margin-left: 0;
				}

				&::after {
					content: '';
					display: block;
					width: 0;
					height: 2px;
					background-color: $red-color;
					position: absolute;
					left: 50%;
					bottom: 0;
					transform: translateX(-50%);
					transition: width 0.3s ease-out;
				}

				&.is-active {
					color: $black-color;
					pointer-events: none;
					cursor: default;

					&::after {
						width: 100%;
					}
				}
			}
		}

		.type-select {
			display: block;

			@include minW(768) {
				display: none;
			}
		}
	}
}
