.recall-check {
	position: relative;

	&__main {
		width: 100%;
		max-width: 60.7rem;
		padding: 0 2rem;
		margin: 0 auto;

		.form {
			&__group {
				@include minW(992) {
					display: flex;
					align-items: flex-end;
				}

				.btn {
					width: 100%;
					min-width: 18rem;
					margin-top: 4rem;
					margin-bottom: 8rem;

					@include minW(992) {
						width: auto;
						margin: 0;
					}
				}
			}

			&__label {
				top: 1.8rem;
			}

			&__error {
				@include minW(768) {
					position: absolute;
					top: 4.8rem;
				}
			}

			&__control {
				text-transform: uppercase;
				height: 5.8rem;
				font-weight: 400;

				@include minW(992) {
					margin-right: 4rem;
					height: auto;
				}
			}
		}
	}
}
