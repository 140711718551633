.box-compare {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -2rem;

  &__item {
    width: 100%;
    padding: 0 2rem;
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 4rem;
    }

    @include minW(768) {
      width: 33.3333%;
      margin-bottom: 4rem;
    }
  }

  &__body {
    position: relative;
    background-color: #F6F6F6;
    padding: 4rem 2rem;
    border-top: 2px solid $black-color;
  }

  &__btn {
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 3.2rem;
    border: 0;
    outline: 0;
    cursor: pointer;
    padding: 8rem 0;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      outline: 0;
    }

    &.is-loader {
      pointer-events: none;
      cursor: default;

      span {
        animation: spin 1s infinite linear;
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.8rem;
      height: 1.8rem;

      &::before,
      &::after {
        position: absolute;
        content: '';
        display: block;
        width: 1.8rem;
        height: 0;
        border-bottom: 4px solid $black-color;
      }

      &::after {
        transform: rotate(90deg);
      }
    }
  }
}
