.slick {
	&-slider {
		position: relative;
		display: block;
		user-select: none;
		touch-action: pan-y;
	}

	&-list {
		position: relative;
		overflow: hidden;
		display: block;
		margin: 0;
		padding: 0;

		&:focus {
			outline: none;
		}

		&.dragging {
			cursor: pointer;
			cursor: hand;
		}
	}

	&-arrow {
		position: absolute;
		font-size: 0;
		border: 0;
		width: 6.9rem;
		height: 1.8rem;
		background-color: transparent;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		top: 80%;
		transform: translateY(-80%);
		outline: 0;
		transition: background-image 0.3s ease-in-out;
		z-index: 9;

		&:focus {
			outline: 0;
		}
	}

	&-prev {
		left: 5rem;
		background-image: url('../img/icons/arrow-left.svg');

		@include hover {
			background-image: url('../img/icons/arrow-left-red.svg');
		}
	}

	&-next {
		right: 5rem;
		background-image: url('../img/icons/arrow-right.svg');

		@include hover {
			background-image: url('../img/icons/arrow-right-red.svg');
		}
	}

	&-disabled {
		display: none !important;
	}
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;

	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;

	[dir="rtl"] & {
		float: right;
	}

	img {
		display: block;
	}

	&.slick-loading img {
		display: none;
	}

	display: none;

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow.slick-hidden {
	display: none;
}
