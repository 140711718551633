.footer {
	position: relative;
	overflow: hidden;
	z-index: 96;

	&--configure {
		.footer {
			&__main {
				padding-top: 10rem;

				@include minW(992) {
					padding-top: 10rem;
				}

				@include minW(1200) {
					padding-top: 10rem;
				}
			}
		}

		.navigation {
			display: none;
		}
	}

	.navigation {
		@media (min-width: 992px) {
			.container {
				max-width: 100%;
			}
		}

		@media (min-width: 1336px) {
			.container {
				max-width: 83.33333%;
			}
		}
	}

	&__main {
		position: relative;
		background-color: $black-color;
		color: $white-color;
		padding-top: 10rem;

		@include minW(992) {
			padding-top: 18.6rem;
		}

		@include minW(1200) {
			padding-top: 16rem;
		}
	}

	&__menu {
		position: relative;
		padding-bottom: 3rem;

		@include minW(992) {
			padding-bottom: 0;
		}
	}

	&__content {
		position: relative;
		padding-bottom: 3rem;
		height: 100%;

		@include minW(992) {
			padding-bottom: 0;
		}

		.social {
			position: absolute;
			left: 0;
			top: 0;

			@include minW(992) {
				right: 0;
				bottom: 5rem;
				left: auto;
				top: auto;
			}
		}
	}

	&__signed {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1.5rem;

		@include minW(768) {
			margin-bottom: 1rem;
		}
	}

	&__foot {
		border-top: 2px solid #434547;
		padding: 2rem 0;

		@include minW(768) {
			margin-top: 4rem;
		}
	}

	&__nav {
		padding-right: 6rem;

		@include minW(768) {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-right: 0;
		}

		.copyright {
			font-size: 1.2rem;
			line-height: 1.2;
			margin-bottom: 0;

			@include minW(768) {
				font-size: 1.4rem;
			}
		}
	}
}

.footer-lang {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;

	&__item {
		position: relative;
		display: flex;
		align-items: center;

		&::before {
			content: '';
			display: none;
			width: 2px;
			height: 1.2rem;
			background-color: $white-color;
			margin: 0 1.2rem;

			@include minW(768) {
				display: block;
			}
		}

		&:first-child {
			&::before {
				display: none;
			}
		}
	}

	&__link {
		font-size: 1.4rem;
		line-height: 1;
		font-weight: 400;
		text-transform: uppercase;
		background-color: $black-color;
		border: 1px solid $light-grey-color;
		color: $white-color;
		padding: 3px 6px;
		font-weight: 700;

		@include minW(992) {
			font-weight: 400;
			border-color: transparent;
			padding: 0;
		}

		&.is-active {
			font-weight: 700;
			background-color: $light-grey-color;
			color: $black-color;

			@include minW(992) {
				background-color: transparent;
				color: $white-color;
			}
		}
	}

	&__text {
		&--long {
			display: none;

			@include minW(768) {
				display: block;
			}
		}

		&--short {
			display: block;

			@include minW(768) {
				display: none;
			}
		}
	}
}

.nav-foot {
	position: relative;

	@include minW(768) {
		margin-bottom: 10rem;
	}

	@include minW(1171) {
		margin-bottom: 5rem;
	}

	&.is-show {
		.nav-foot {
			&__title {
				&::after {
					transform: rotate(135deg);
					border-color: $red-color;
				}
			}

			&__body {
				max-height: 300rem;
				transition: max-height 0.3s cubic-bezier(0.89, 0.01, 1, 0.46) 0ms, opacity 50ms ease 0ms,
					visibility 0ms ease 0ms !important;
			}
		}
	}

	&__title {
		font-size: 1.6rem;
		line-height: 1;
		margin-bottom: 1.5rem;

		@include minW(768) {
			font-size: 2.1rem;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			right: 0;
			top: 2px;
			width: 5px;
			height: 5px;
			border-left: 2px solid $white-color;
			border-bottom: 2px solid $white-color;
			transform: rotate(-45deg);
			transition: border 0.3s ease-out;

			@include minW(768) {
				display: none;
			}
		}
	}

	&__body {
		overflow: hidden;
		max-height: 0;
		transition: max-height 0.3s cubic-bezier(0.04, 0.99, 0.07, 0.99) 50ms, opacity 50ms ease 0.3s,
			visibility 0ms ease 0.3s;

		@include minW(768) {
			max-height: none;
			transition: none;
		}
	}

	&__menu {
		list-style: none;
		margin-bottom: 3rem;
		padding: 0;

		@include minW(768) {
			margin-bottom: 0;
		}
	}

	&__item {
		margin-bottom: 1rem;
	}

	&__link {
		font-size: 1.4rem;
		line-height: 1.4rem;
		color: $light-grey-color;

		@include hover {
			text-decoration: underline;
		}
	}
}

.hotline {
	position: relative;
	display: flex;
	font-size: 2rem;
	line-height: 1;
	padding-top: 5rem;

	@include minW(768) {
		font-weight: 700;
		font-size: 2.1rem;
		padding-top: 0;
		// justify-content: flex-end;
	}

	&__item {
		margin-bottom: 10px;
	}

	&__link {
		font-size: 1.8rem;
		line-height: 1.2rem;
		/* margin-left: 5px; */
		color: $white-color;
		display: flex;
		white-space: nowrap;

		@include minW(640) {
			line-height: 2rem;
		}

		@include minW(992) {
			font-size: 1.2rem;
			line-height: 1.2rem;
		}

		@include minW(1200) {
			font-size: 1.4rem;
			line-height: 1.4rem;
		}

		@include minW(1400) {
			font-size: 1.8rem;
			line-height: 1.8rem;
		}

		@include minW(1500) {
			font-size: 2.1rem;
			line-height: 2.1rem;
		}

		@include hover {
			text-decoration: underline;
		}

		.icon {
			margin-right: 1rem;
			width: 2rem;
			height: 2rem;

			@include minW(640) {
				width: 2.4rem;
				height: 2.4rem;
			}

			@include minW(992) {
				width: 1.6rem;
				height: 1.6rem;
				margin-top: 3px;
			}

			@include minW(1200) {
				width: 2rem;
				height: 2rem;
			}

			@include minW(1500) {
				width: 2.4rem;
				height: 2.4rem;
			}
		}
	}
}

.social {
	display: flex;
	align-items: center;

	&__item {
		display: block;
		margin-left: 2rem;

		&:first-child {
			margin-left: 0;
		}
	}
}

.nav-bot {
	position: relative;
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 3rem;

	@include minW(768) {
		margin-bottom: 0;
	}

	&__item {
		width: 50%;

		@include minW(768) {
			margin-right: 1.6rem;
			width: auto;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__link {
		color: $white-color;
		font-size: 1.4rem;
		line-height: 1.4rem;

		@include hover {
			text-decoration: underline;
		}
	}
}

.cta-contact {
	position: fixed;
	right: 2rem;
	bottom: 2rem;
	z-index: 98;
	transition: bottom 0.3s ease-out;
	user-select: none;

	@include minW(768) {
		right: 3rem;
		bottom: 3rem;
	}

	&.is-show {
		.cta-contact {
			// &__body {
			// 	max-height: 100rem;
			// }

			&__btn {
				&::before {
					background-image: url('../img/icons/close.svg');
				}
			}
		}
	}

	&.is-current,
	&.is-sticky {
		bottom: 8rem;
	}

	&.is-docked {
		bottom: 2rem;

		@include minW(768) {
			bottom: 3rem;
		}
	}

	&__btn {
		position: relative;
		width: 4.5rem;
		height: 4.5rem;
		background-color: $red-color;
		border-radius: 50%;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		z-index: 2;

		@include minW(768) {
			width: 6.5rem;
			height: 6.5rem;
		}

		&::before {
			content: '';
			display: block;
			width: 3rem;
			height: 3rem;
			background-image: url('../img/icons/cta-contact.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			transition: background-image 0.15s ease-out;

			@include minW(768) {
				width: 4rem;
				height: 4rem;
			}
		}
	}

	&__body {
		position: absolute;
		bottom: 5rem;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		overflow: hidden;
		height: 0;
		//transition: all 0.5s cubic-bezier(0, 1, 0, 1);

		@include minW(768) {
			bottom: 7.5rem;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;

		a {
			display: block;
			margin-bottom: 5px;
		}
	}
}
.company-info {
	font-weight: 400;
	h4 {
		text-transform: uppercase;
	}
	@include minW(768) {
		margin-top: 5rem;
		h4 {
			white-space: nowrap;
		}
	}
	ul {
		list-style-type: square;
		li {
			margin-bottom: 1rem;
			font-size: 1.4rem;
			line-height: 1.4;
			color: $light-grey-color;
		}
	}
}
.mb-6 {
	margin-bottom: 6vw;
}
.nav-more {
	display: none;
}
.mt-4 {
	margin-top: 4vw;
}
.chon-filehuongdan {
	.variant-box__content {
		h4 {
			margin-bottom: 0;
		}
	}
}
.btn--text--red {
	color: $red-color;
}
.is-blur {
	> * {
		opacity: 0.5;
		pointer-events: none;
		cursor: default;
	}
}
// .pango-noti-widget-wrapper,
// .cta-contact,
// .header__foot {
// 	display: none;
// }
.pb-0 {
	padding-bottom: 0 !important;
}
.grecaptcha-badge {
	display: none !important;
}
