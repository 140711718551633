.side-navigation {
  position: fixed;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
  display: none;
  z-index: 8;

  @include minW(768) {
    display: block;
  }

  &__item {
    margin: 5px 0;
    border: 0;
    height: 3rem;
    width: 4rem;
    position: relative;
    background-color: transparent;
    transition: height .15s ease-out;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      background-color: #a3a9ad;
      position: absolute;
      top: 0;
      left: 50%;
    }

    @include hover {
      .side-navigation {
        &__label {
          max-width: 500px;
          padding: 0 8px 0 25px;
        }
      }
    }

    &.is-active {
      height: 6rem;

      &::before {
        background-color: #ed0000;
      }
    }
  }

  &__label {
    overflow: hidden;
    max-width: 0;
    transition: max-width .3s ease, padding .15s ease;
    position: absolute;
    display: block;
    left: 2.2rem;
    white-space: nowrap;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 5px 15px -5px rgb($black-color, 0.1);
    font-size: 1.2rem;
    line-height: 3rem;
    color: #5a646e;
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: $white-color;
  }
}
