.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 10rem;
	background-color: $black-color;
	transition: transform 0.3s ease-out;
	z-index: 99;

	&.is-home {
		background-color: rgba($black-color, 0.8);
	}

	&.is-move {
		transform: translateY(-100%);
	}

	&.is-show {
		.header {
			&__main {
				height: 100%;
				background-color: #fff;

				@include minW(1200) {
					height: 10rem;
					background-color: transparent;
				}
			}

			&__body {
				display: block;
				position: relative;
			}
		}

		.logo {
			.img {
				&--black {
					opacity: 1;

					@include minW(1200) {
						opacity: 0;
					}
				}
			}
		}
	}

	&.is-open {
		.logo {
			.img {
				&--black {
					@include minW(1200) {
						opacity: 1;
					}
				}
			}
		}

		.nav-top {
			&__link {
				color: $black-color;

				@include hover {
					color: $red-color;
				}
			}
		}

		.nav-menu {
			&__title {
				color: $black-color;

				@include hover {
					color: $red-color;

					&::after {
						border-color: $red-color;
					}
				}

				&::after {
					border-color: $black-color;
				}
			}
		}

		.nav-lang {
			&__link {
				&.is-active {
					.nav-lang {
						&__icon {
							@include minW(992) {
								background-color: $black-color;
								color: $white-color;
							}
						}
					}
				}
			}

			&__icon {
				border-color: $black-color;
				background-color: $white-color;
				color: $black-color;
			}
		}
	}

	&__main {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		min-height: 10rem;
		z-index: 2;

		@include minW(1200) {
			position: static;
			top: auto;
			left: auto;
			overflow-x: unset;
			overflow-y: unset;
		}
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform: translateX(100%);
		display: block;
		z-index: 1;

		@include minW(1200) {
			display: none;
		}

		&::after {
			content: '';
			background-image: radial-gradient(farthest-corner at -231px 176px,
					#f5f5f5 0,
					transparent 100%);
			transform: translateX(100px) skew(-30deg);
			transition: transform 0ms ease-out;
			position: absolute;
			opacity: 1;
			display: block;
			height: 100%;
			width: 4000px;
			top: 0;
			left: 0;
		}
	}

	&__body {
		position: fixed;
		display: none;
		min-height: 100%;
		padding: 10rem 2rem 2rem;
		z-index: 2;

		@include minW(1200) {
			position: static;
			padding: 0;
			display: block;
			min-height: initial;
			transform: none;
		}
	}

	&__foot {
		padding-top: 10rem;
		width: 100%;

		@include minW(1200) {
			padding-top: 0;
		}
	}
}

.nav-top {
	list-style: none;

	@include minW(1200) {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		top: 1rem;
		right: 2rem;
		margin-bottom: 0;
		z-index: 3;
	}

	&__item {
		@include minW(1200) {
			margin-left: 1.8rem;
		}

		&:first-child {
			@include minW(1200) {
				margin-left: 0;
			}
		}
	}

	&__link {
		transition: color 0.3s ease-out;
		display: flex;
		align-items: center;
		font-weight: 400;
		color: #5a646e;
		line-height: 4.5rem;

		@include minW(1200) {
			font-size: 1.4rem;
			line-height: 1;
			font-weight: 500;
			color: $white-color;
		}

		@include hover {
			color: $red-color;
		}

		.icon {
			display: block;
			margin-right: 8px;

			@include minW(1200) {
				display: none;
			}
		}
	}
}

.logo {
	position: absolute;
	top: 1.3rem;
	left: 50%;
	transform: translateX(-50%);
	width: 7.4rem;
	height: 7.4rem;
	z-index: 3;

	@include minW(992) {
		left: 2rem;
		transform: none;
	}

	.img {
		width: 100%;
		height: 100%;
		position: absolute;

		&--black {
			opacity: 0;
		}
	}
}

.nav-menu {
	list-style: none;

	@include minW(1200) {
		position: absolute;
		display: flex;
		align-items: center;
		top: 5.4rem;
		left: 12.6rem;
		margin-bottom: 0;
	}

	&__item {
		@include minW(1200) {
			margin-left: 4rem;
		}

		&:first-child {
			@include minW(1200) {
				margin-left: 0;
			}
		}

		&.nav-menu__item--img {
			width: 46%;
			margin-top: 2rem;
			@include minW(1200) {
				width: 10vw;
				margin-top: -0.5vw;
			}
			@include minW(1600) {
				margin-top: -1vw;
			}
			@include minW(1900) {
				width: 9vw;
				// margin-top: -1vw;
			}
		}

		&.is-active {
			.nav-menu {
				&__title {
					color: $red-color;

					&::after {
						transform: rotate(135deg);
						top: 2.5rem;
						border-color: $red-color;

						@include minW(1200) {
							top: 4px;
						}
					}
				}
			}
		}
	}

	&__title {
		position: relative;
		color: $black-color;
		font-size: 2.8rem;
		line-height: 2;
		font-weight: 400;
		padding-right: 3rem;
		user-select: none;
		cursor: pointer;
		display: block;
		transition: color 0.3s ease-out;
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;
		z-index: 3;

		@include minW(1200) {
			color: $white-color;
			font-size: 1.3rem;
			line-height: 1;
			font-weight: 700;
			letter-spacing: 1px;
			text-transform: uppercase;
		}

		@include minW(1400) {
			color: $white-color;
			font-size: 1.4rem;
			line-height: 1;
			letter-spacing: 3px;
			text-transform: uppercase;
		}

		@include hover {
			color: $red-color;

			&::after {
				border-color: $red-color;
			}
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			right: 0;
			top: 2.5rem;
			width: 8px;
			height: 8px;
			border-left: 2px solid #c8c8c8;
			border-bottom: 2px solid #c8c8c8;
			transform: rotate(-45deg);
			transition: all 0.3s ease-out;

			@include minW(1200) {
				top: -2px;
			}
		}
	}
}

.nav-sub {
	height: 0;
	overflow: hidden;

	@include minW(1200) {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		max-height: none;
		overflow: unset;
		padding-top: 10rem;
		background-color: $white-color;
		opacity: 0;
		visibility: hidden;
		z-index: 1;
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform: translateX(100%);
		display: none;

		@include minW(1200) {
			display: block;
		}

		&::before {
			content: '';
			display: block;
			background-image: radial-gradient(farthest-corner at -231px 176px,
					#f5f5f5 0,
					transparent 100%);
			transform: translateX(15%) skew(-30deg);
			position: absolute;
			opacity: 1;
			display: block;
			height: 100%;
			width: 4000px;
			top: 0;
			left: 0;
		}
	}

	&__main {
		@include minW(1200) {
			position: relative;
			width: 100%;
			height: 100%;
			overflow: hidden;
			overflow-y: auto;
		}
	}

	&__item {
		@include minW(1200) {
			padding: 2rem 9rem;
		}
	}

	&__thumb {
		display: none;

		@include minW(1200) {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			max-width: 60rem;
			width: 100%;
			display: block;
		}

		@include minW(1920) {
			max-width: 50vw;
		}
	}

	&__body {
		@include minW(1200) {
			padding-left: 66.6667%;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			// margin-top: 92px;
		}
	}
}

.nav-model {
	list-style: none;

	@include minW(1200) {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		margin: 0 -2rem;
	}

	&__item {
		@include minW(1200) {
			width: 25%;
			padding: 0 2rem;
		}
	}

	&__link {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 1.6rem 0;
		color: $black-color;
		margin: 0 -5px;

		@include minW(1200) {
			text-align: center;
			display: block;
			margin: 0;
		}
	}

	&__thumb,
	&__body {
		width: 50%;
		padding: 0 5px;

		@include minW(1200) {
			width: auto;
			padding: 0;
		}
	}

	&__title {
		font-size: 1.6rem;
		line-height: 1.25;
		text-transform: uppercase;
		margin-bottom: 0;
	}

	&__desc {
		font-size: 1.3rem;
		line-height: 1.6;
		color: #5a646e;
		margin-bottom: 0;
	}
}

.nav-button {
	text-align: center;

	.btn {
		margin-bottom: 1.5rem;

		@include minW(992) {
			margin-left: 2rem;
			margin-bottom: 0;
		}

		&:first-child {
			margin-left: 0;
		}
	}
}

.nav-right {
	list-style: none;
	margin: 2rem 0;
	padding-left: 3rem;
	border-left: 1px solid #cac8c8;

	@include minW(1200) {
		padding: 0 2rem;
		margin: 0;
		border-left: 0;
	}

	&__item {
		margin-top: 1rem;

		@include minW(1200) {
			padding: 0.2rem 0;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	&__link {
		font-size: 1.6rem;
		line-height: 1.3;
		color: #5c666f;

		@include minW(1200) {
			font-size: 2.1rem;
			line-height: 1.3;
			transition: color 0.3s ease-out;
		}

		@include hover {
			color: $black-color;
		}
	}
}

.nav-lang {
	list-style: none;
	margin-bottom: 0;
	margin-top: 3.5rem;

	@include minW(1200) {
		position: absolute;
		right: 2rem;
		top: 4.6rem;
		display: flex;
		margin-top: 0;
		z-index: 2;
	}

	&__link {
		&.is-active {
			.nav-lang {
				&__icon {
					background-color: $black-color;
					color: $white-color;

					@include minW(1200) {
						background-color: #c7c7c7;
						color: $black-color;
					}
				}
			}
		}
	}

	&__icon {
		color: $black-color;
		font-size: 1.4rem;
		line-height: 1.4rem;
		font-weight: 700;
		text-transform: uppercase;
		border: 1px solid $black-color;
		background-color: $white-color;
		padding: 2px 1rem 4px;
		display: inline-block;
		width: 3.8rem;

		@include minW(1200) {
			border: 1px solid #c7c7c7;
			background-color: $black-color;
			color: $white-color;
		}
	}

	&__text {
		margin-left: 6px;
		color: #5a646e;

		@include minW(1200) {
			display: none;
		}
	}
}

.nav-toggle {
	position: absolute;
	right: 1rem;
	top: 3.2rem;
	width: 3.6rem;
	height: 3.6rem;
	border: 0;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: 0;
	z-index: 3;

	@include minW(1200) {
		display: none;
	}

	&:focus {
		outline: 0;
	}

	&.is-active {
		.nav-toggle {
			&__icon {
				background-color: transparent;

				&::before,
				&::after {
					top: 0;
					background-color: $red-color;
				}

				&::before {
					transform: rotate(45deg);
				}

				&::after {
					transform: rotate(-45deg);
				}
			}
		}
	}

	&__icon {
		width: 2.2rem;
		height: 2px;
		background-color: $white-color;
		display: block;
		position: relative;

		&::before,
		&::after {
			content: '';
			display: block;
			width: 2.2rem;
			height: 2px;
			background-color: $white-color;
			position: absolute;
			transition: transform 0.3s ease-out;
		}

		&::before {
			top: -7px;
		}

		&::after {
			top: 7px;
		}
	}
}

.nav-more-wrap {
	display: none;
	position: relative;
	width: 100%;
	height: 4.6rem;
	overflow: hidden;
	overflow-x: auto;

	@include minW(1200) {
		display: block;
	}
}

.nav-more {
	position: absolute;
	list-style: none;
	padding: 0;
	font-size: 1.2rem;
	line-height: 1.6rem;
	text-transform: uppercase;
	margin-bottom: 0;
	min-width: 100%;

	@include minW(1200) {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__item {
		position: relative;
		margin: 0 1.5rem;
		padding: 1.5rem 0;

		&.is-active {
			&::after {
				width: 100%;
			}
		}

		@include hover {
			&::after {
				width: 100%;
			}
		}

		&::after {
			content: '';
			display: block;
			width: 0;
			height: 2px;
			background-color: $red-color;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 0;
			transition: width 0.3s ease-out;
		}
	}

	&__link {
		color: $white-color;
		transition: color 0.3s ease-out;
		white-space: nowrap;


		@include hover {
			color: $red-color;
		}
	}
}

.nav-mobile {
	position: relative;
	background-color: $black-color;
	color: $white-color;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 1.2rem;
	line-height: 1.6rem;
	letter-spacing: 1px;
	text-transform: uppercase;
	padding: 1.5rem 0;
	border-top: 1px solid #4a4a4a;

	@include minW(1200) {
		display: none;
	}

	&__title {
		padding-left: 2rem;
		font-weight: 700;
	}
}
