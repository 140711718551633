.concept-cars {
	.heritage-image__thumb {
		margin-bottom: 3rem;
	}
	.mfourthree {
		padding: 4rem 0;
	}
	.heritage-image__content {
		margin: 4rem 0 8rem;
	}
	.carousel-tabbed {
		@include minW(768) {
			padding-top: 0;
		}

		.slick__item {
			color: $dark-grey-color;
			h4 {
				padding-top: 4rem;
			}
			&__content {
				max-width: 44rem;
			}
		}
	}
	.discovery .btn {
		color: $white-color;
		padding: 0;
		text-align: center;
		&:hover {
			color: $red-color;
		}
	}
}
