.tradein-update {
	.ces-summary {
		margin-bottom: 2rem;
		.ces-summary__model-key {
			text-transform: capitalize;
		}
		.ces-summary__text {
			@include minW(992) {
				margin-bottom: 0.5rem;
			}
		}

		.ces-summary__noted {
			display: block;
			text-align: center;
			font-size: 12px;
		}
	}
	.title--3 {
		margin-bottom: 4rem;
		margin-top: 3rem;
	}
	.mt-4 {
		margin-top: 2rem !important;
		margin-bottom: 3rem !important;
	}
	.copy-wrapper {
		@include minW(992) {
			width: 80%;
		}

		margin: 0 auto;
		text-align: center;
	}
	position: relative;

	&__item {
		padding: 3rem 0;
	}
}

.tradein-step {
	position: relative;
	overflow: hidden;
	height: 0;

	.cost-estimate-summary {
		display: block;
		padding-bottom: 0;
	}

	.ces-summary {
		margin-bottom: 0;
	}

	&--car-use {
		height: auto;
	}

	&--result {
		.summary__copy {
			pointer-events: all;
			opacity: 1;
		}
	}

	.sosanhxe-button,
	.button-group {
		text-align: center;

		.btn + .btn {
			margin: 0 1rem;
		}
	}
}

.compare-car {
	position: relative;
	.ttx {
		@include minW(992) {
			text-align: right;
		}
	}
	&__bg {
		position: absolute;
		top: 0;
		left: 50%;
		width: 100%;
		height: 100%;
		background: #f7f7f7;
		background: -moz-linear-gradient(top, #f7f7f7 0%, #fbfbfb 100%);
		background: -webkit-linear-gradient(top, #f7f7f7 0%, #fbfbfb 100%);
		background: linear-gradient(to bottom, #f7f7f7 0%, #fbfbfb 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f7f7f7", endColorstr="#fbfbfb", GradientType=0);
		transform: skewX(-25deg);
	}

	&__body {
		position: relative;
		z-index: 2;
		padding-top: 3rem;

		.row {
			@include minW(992) {
				align-items: center;
			}

			&__col {
				&:first-child {
					margin-bottom: 40px;

					@include minW(992) {
						margin-bottom: 0;
					}
				}
			}
		}
		.title--3 {
			.car-new-model {
				text-transform: uppercase;
			}
			@include minW(992) {
				margin-top: 0 !important;
				margin-bottom: 1rem !important;
			}
		}
		.car-use-variant {
			text-transform: capitalize;
		}
		p {
			margin-bottom: 0;
		}

		.img--icon {
			position: absolute;
			top: 70%;
			left: 48%;
			transform: translate(-50%, -50%);
			max-width: 80px;
			width: 100%;
			display: none;
			opacity: 0.5;

			@include minW(992) {
				display: block;
			}
		}
	}

	&__foot {
		text-align: center;
		position: relative;
		z-index: 2;

		@include minW(992) {
			text-align: right;
		}

		.title {
			color: #686d71;
			margin-bottom: 0;
		}
	}

	&__button {
		.btn + .btn {
			margin-top: 1rem;

			@include minW(992) {
				margin-top: 0;
				margin-left: 1.5rem;
			}
		}
	}
}

.price-more {
	font-weight: 700;
	font-size: 2.1rem;
	margin-bottom: 1rem;

	@include minW(768) {
		font-size: 2.4rem;
		line-height: 3.2rem;
	}
}

.compare-car__note {
	font-size: 1.2rem;
	color: #686d71;
	line-height: 1.4;
	margin-bottom: 3rem;

	@include minW(768) {
		margin-bottom: 4rem;
	}
}

// for testing
// .nav-more {
// 	display: none !important;
// }
.sosanhxe-button {
	margin-top: 4rem;
}

.no-price {
	&__note {
		font-size: 12px;
		color: #686d71;
		line-height: 1.4;
	}
}
.yes-price {
	.title--3 {
		@include minW(992) {
			margin-top: 0 !important;
			margin-bottom: 1rem !important;
		}
	}
}

.form {
	&__head {
		position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

		@include minW(992) {
			display: block;
		}

		.btn {
			color: $cold-grey-color;

			@include minW(992) {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}

			&__text {
				display: flex;
				align-items: center;

				&::before {
					content: '';
					display: block;
					width: 10px;
					height: 10px;
					border-left: 2px solid $cold-grey-color;
					border-bottom: 2px solid $cold-grey-color;
					transform: rotate(45deg);
					margin-left: 5px;
					margin-top: 2px;
				}
			}

			@include hover {
				.btn {
					&__text {
						&::before {
							border-color: $red-color;
						}
					}
				}
			}

			&:after {
				display: none;
			}
		}
	}
}