.configurator3d {
	position: relative;
}

.configurator3d-nav {
	position: fixed;
	top: 10rem;
	left: 0;
	width: 100%;
	z-index: 2;

	&__item {
		display: none;

		&.is-active {
			display: block;
		}
	}

	&__body {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 4rem;
	}

	&__link {
		border: 0;
		background-color: transparent;
		outline: 0;
		color: $white-color;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 1.2rem;
		line-height: 1.8rem;
		letter-spacing: 3px;

		&:focus {
			outline: 0;
		}
	}
}

.configurator3d-choose {
	position: relative;

	&__item {
		display: none;

		&.is-active {
			display: block;
		}
	}
}

.configurator3d-slider {
	position: relative;
	width: 100%;
	min-height: 100vh;

	&__body {
		position: fixed;
		width: 100%;
		height: 100vh;
		min-height: 60rem;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 20.8rem 0 10rem;

		.slick {
			&-dots {
				list-style: none;
				display: flex;
				align-items: center;
				justify-content: center;

				li {
					position: relative;
					width: 4.4rem;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: width .2s cubic-bezier(0, .01, .63, 1.01) .3s;

					&.slick-active {
						width: 8.8rem;

						button {
							&::before {
								background-color: #fff;
							}
						}
					}

					button {
						width: 100%;
						height: 2px;
						margin: 4px;
						padding: 4px 0;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: transparent;
						border: 0;
						font-size: 0;
						outline: 0;

						&::before {
							content: '';
							display: block;
							width: 100%;
							height: 2px;
							background-color: #a3a9ad;
						}
					}
				}
			}
		}
	}
}

.configurator3d-select {
	position: relative;
	margin-top: -4rem;
	z-index: 2;

	&__main {
		background-color: #fff;
		padding: 6rem 0;
	}

	&__button {
		position: absolute;
		left: 50%;
		top: -2.2rem;
		transform: translateX(-50%);

		.btn {
			min-width: 27rem;
		}
	}

	&__body {
		text-align: center;
		color: #5a646e;
		font-size: 1.6rem;
		line-height: 2.4rem;
		font-weight: 500;
		color: #5a646e;
	}

	&__title {
		font-size: 1.2rem;
		line-height: 1.6rem;
		text-transform: uppercase;
		margin-bottom: 3rem;
	}

	&__desc {
		span {
			display: block;
		}
	}
}

.configurator3d-card {
	position: relative;
	text-align: center;
	color: $white-color;

	&__thumb {
		height: 80%;

		.img {
			width: auto;
			height: auto;
			max-height: 100%;
		}
	}
}
