.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 1.2rem;
	border: 2px solid $black-color;
	font-size: 1.2rem;
	line-height: 1.6rem;
	font-weight: 700;
	text-transform: uppercase;
	color: $black-color;
	letter-spacing: 3px;
	outline: 0;
	background-color: transparent;
	background-image: linear-gradient(-60deg, $black-color 50%, transparent 0);
	background-size: 400% 400%;
	background-position: 0 50%;
	width: 100%;
	transition: color 0.3s ease-in-out, background-position 0.3s ease-in-out;

	@include minW(768) {
		width: auto;
	}

	@include minW(992) {
		padding: 1.2rem 3.6rem;
		font-size: 1.4rem;
		line-height: 2rem;
		min-width: 20vw;
	}
	@include minW(1600) {
		min-width: 280px;
	}

	&:focus {
		outline: 0;
	}

	@include hover {
		color: $white-color;
		background-image: linear-gradient(-60deg, $black-color 50%, transparent 0);
		background-position: 99% 50%;
	}

	&.is-disabled {
		pointer-events: none;
		cursor: default;
		border: 2px solid $light-grey-color;
		color: $light-grey-color;
	}

	&--black {
		background-color: $black-color;
		color: $white-color;
	}

	&--full {
		width: 100%;

		@include minW(992) {
			width: auto;
			min-width: 20vw;
		}
		@include minW(1600) {
			min-width: 280px;
		}
	}

	&--white {
		border-color: $white-color;
		color: $white-color;
		background-image: linear-gradient(-60deg, $white-color 50%, transparent 0);
		transition: color 0.3s ease-in-out, background-position 0.3s ease-in-out;

		@include hover {
			color: $black-color;
			background-image: linear-gradient(-60deg, $white-color 50%, transparent 0);
			background-position: 99% 50%;
		}
	}

	&--red {
		background-color: $red-color;
		background-image: linear-gradient(-60deg, $red-color 50%, transparent 0);
		color: $white-color;
		border-color: transparent !important;

		@include hover {
			color: $white-color;
			background-image: linear-gradient(-60deg, $black-color 50%, transparent 0);
			background-position: 99% 50%;
		}
	}

	&--link {
		border: 0;
		padding: 0;
		background-image: none;
		width: auto !important;
		min-width: auto !important;

		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 2px;
			background-color: $black-color;
			position: absolute;
			bottom: 0;
			left: 0;
			transition: background-color 0.3s ease;
		}

		@include hover {
			color: $red-color;
			background-image: none;

			&::after {
				background-color: $red-color;
			}
		}
	}

	&--link-white {
		color: $white-color;
		border: 0;
		padding: 0;
		background-image: none;
		width: auto !important;
		min-width: auto !important;

		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 2px;
			background-color: $white-color;
			position: absolute;
			bottom: 0;
			left: 0;
			transition: background-color 0.3s ease;
		}

		@include hover {
			color: $red-color;
			background-image: none;

			&::after {
				background-color: $red-color;
			}
		}
	}

	&__text {
		text-align: center;
	}
}
