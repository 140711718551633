.thank-you {
	position: relative;
	background-color: #F6F6F6;
	padding: 7.7rem 0 11.6rem;

	&__head {
		margin-bottom: 8rem;

		.row {
			align-items: center;
		}
	}

	&__model {
		margin-bottom: 1.5rem;

		span {
			display: block;
		}
	}

	&__name {
		text-transform: uppercase;
	}

	&__body {
		background-color: $white-color;
		padding: 5rem 4rem;

		p {
			>span {
				display: block;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__address {
		display: inline-flex;
		margin-left: 5px;
	}

	&__phone {
		display: inline-flex;
		align-items: center;
		color: $red-color;

		.icon {
			margin: 0 1rem;
		}
	}

	&__link {
		display: inline-flex;
		color: $red-color;
		margin-left: 5px;
	}

	&__noted {
		font-size: 1.4rem;
	}
}


.thank-contact {
	position: relative;
	margin-bottom: 2rem;

	&__address {
		margin-bottom: 2rem;
	}

	p {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		line-height: 2.4rem;
		padding-left: 2.4rem;
		margin-bottom: 5px;

		.icon {
			position: absolute;
			left: 0;
			top: 8px;
		}

		a {
			color: $red-color;
			transition: all 0.3s ease-out;

			@include hover {
				text-decoration: underline;
			}
		}
	}

	&__text {
		margin-bottom: 0;
		display: flex;
		align-items: center;

		span {
			position: relative;
			display: block;
			margin-left: 4rem;

			&::before {
				content: '';
				display: block;
				width: 3px;
				height: 3px;
				border-radius: 50%;
				background-color: $black-color;
				position: absolute;
				left: -2rem;
				top: 50%;
				transform: translateY(-50%);
			}

			&:first-child {
				margin-left: 0;

				&::before {
					display: none;
				}
			}
		}
	}
}
