.taber {
  position: relative;

  &__menu {
    display: flex;
    margin-bottom: 4rem;
  }

  &__link {
    display: block;
    width: auto;
    font-size: 1.6rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-decoration: none;
    color: $black-color;
    opacity: 0.7;
    position: relative;
    transition: all 0.3s ease-out;
    margin-left: 3rem;
    white-space: nowrap;

    &:first-child {
      margin-left: 0;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 2px;
      background-color: $red-color;
      transition: all 0.3s ease-out;
    }

    @include hover {
      &::after {
        width: 100%;
      }
    }

    &.is-active {
      font-weight: 700;

      &::after {
        width: 100%;
      }
    }
  }

  &__content {
    position: relative;
    height: 0;
    opacity: 0;
    overflow: hidden;
    color: $black-color;

    &.is-active {
      opacity: 1;
      height: auto;
      overflow: unset;
    }
  }

  &__thumb {
    font-size: 1rem;

    p {
      margin-top: -10%;
    }
  }
}
