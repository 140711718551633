.pt-8 {
	padding-top: 8rem;
}
.pt-4 {
	padding-top: 4rem;
}
.price-box {
	@include minW(992) {
		width: 70%;
	}
	margin: 10rem auto;
	img {
		width: 60%;
		margin: 0 auto;
		display: block;
	}
	th,
	td {
		padding: 1rem;
		border: 1px solid rgba(197, 199, 201, 0.21);
	}
	tr {
		th:nth-child(1) {
			width: 25%;
		}
		th:nth-child(2) {
			width: 25%;
		}
		th:nth-child(3) {
			width: 50%;
		}
	}
	tr:nth-child(2n + 1) td,
	th {
		background-color: rgba(197, 199, 201, 0.21);
	}
	.cta-row {
		&__body {
			padding: 2rem 0;
		}
		@include minW(992) {
			padding: 4rem 0;
			&__link {
				justify-content: center;
			}
		}
	}
}
.warranty__content {
	table {
		max-width: 100%;
		display: block;
	}
	th,
	td {
		padding: 1rem;
		border: 1px solid rgba(197, 199, 201, 0.21);
	}
	tr {
		th:nth-child(1) {
			width: 25%;
		}
		th:nth-child(2) {
			width: 25%;
		}
		th:nth-child(3) {
			width: 50%;
		}
	}
	tr:nth-child(2n + 1) td,
	th {
		background-color: rgba(197, 199, 201, 0.21);
	}
}
