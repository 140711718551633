.recall-result {
	position: relative;
	margin-bottom: 0rem;

	&__thumb {
		background-color: $grey-color;
		padding: 4rem 0;

		.img--full {
			@include minW(1200) {
				width: 60%;
				margin: 0 auto;
			}
		}
	}

	&__body {
		padding: 8rem 0;

		.row {
			margin: 8rem 0 4rem;

			a {
				text-decoration: underline;

				@include hover {
					color: $red-color;
				}
			}
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		text-align: center;
		flex: 1 0 auto;
		height: 100%;
		overflow: hidden;
		position: relative;
	}

	&__label {
		margin-bottom: 1rem;
	}

	&__value {
		background-color: $grey-color;
		padding: 1.1rem 3rem;
		text-align: center;
		display: flex;
		flex: 1 0 auto;
		align-items: center;
		justify-content: center;
	}
}
