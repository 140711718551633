.container {
	width: 100%;
	padding: 0 2rem;
	margin: 0 auto;

	@include minW(992) {
		max-width: 83.33333%;
	}

	&--small {
		max-width: 110rem;
	}

	&--fluid {
		max-width: 100%;
	}
}

.row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -2rem;

	&--row {
		flex-direction: row;
	}

	&--row-reverse {
		flex-direction: row-reverse;
	}

	&--column {
		flex-direction: column;
	}

	&--column-reverse {
		flex-direction: column-reverse;
	}

	&--wrap-reverse {
		flex-wrap: wrap-reverse;
	}

	&--nowrap {
		flex-wrap: nowrap;
	}

	&--justify-content-start {
		justify-content: flex-start;
	}

	&--justify-content-end {
		justify-content: flex-end;
	}

	&--justify-content-center {
		justify-content: center;
	}

	&--justify-content-between {
		justify-content: space-between;
	}

	&--justify-content-around {
		justify-content: space-around;
	}

	&--justify-content-evenly {
		justify-content: space-evenly;
	}

	&--align-items-start {
		align-items: flex-start;
	}

	&--align-items-end {
		align-items: flex-end;
	}

	&--align-items-center {
		align-items: center;
	}

	&--align-items-baseline {
		align-items: baseline;
	}

	&--align-items-stretch {
		align-items: stretch;
	}

	&--align-content-start {
		align-content: flex-start;
	}

	&--align-content-end {
		align-content: flex-end;
	}

	&--align-content-center {
		align-content: center;
	}

	&--align-content-between {
		align-content: space-between;
	}

	&--align-content-around {
		align-content: space-around;
	}

	&--align-content-stretch {
		align-content: stretch;
	}

	&--align-self-start {
		align-self: flex-start;
	}

	&--align-self-end {
		align-self: flex-end;
	}

	&--align-self-center {
		align-self: center;
	}

	&--align-self-baseline {
		align-self: baseline;
	}

	&--align-self-stretch {
		align-self: stretch;
	}

	&__col {
		flex-shrink: 0;
		width: 100%;
		max-width: 100%;
		padding: 0 2rem;

		&--auto {
			flex: 0 0 auto;
			width: auto;
		}

		&--1 {
			flex: 0 0 auto;
			width: 8.3333%;
		}

		&--2 {
			flex: 0 0 auto;
			width: 16.6667%;
		}

		&--3 {
			flex: 0 0 auto;
			width: 25%;
		}

		&--4 {
			flex: 0 0 auto;
			width: 33.3333%;
		}

		&--5 {
			flex: 0 0 auto;
			width: 41.6667%;
		}

		&--6 {
			flex: 0 0 auto;
			width: 50%;
		}

		&--7 {
			flex: 0 0 auto;
			width: 58.3333%;
		}

		&--8 {
			flex: 0 0 auto;
			width: 66.6667%;
		}

		&--9 {
			flex: 0 0 auto;
			width: 75%;
		}

		&--10 {
			flex: 0 0 auto;
			width: 83.3333%;
		}

		&--11 {
			flex: 0 0 auto;
			width: 91.6667%;
		}

		&--12 {
			flex: 0 0 auto;
			width: 100%;
		}

		&--offset {
			&-1 {
				margin-left: 8.3333%;
			}

			&-2 {
				margin-left: 16.6667%;
			}

			&-3 {
				margin-left: 25%;
			}

			&-4 {
				margin-left: 33.3333%;
			}

			&-5 {
				margin-left: 41.6667%;
			}

			&-6 {
				margin-left: 50%;
			}

			&-7 {
				margin-left: 58.3333%;
			}

			&-8 {
				margin-left: 66.6667%;
			}

			&-9 {
				margin-left: 75%;
			}

			&-10 {
				margin-left: 83.3333%;
			}

			&-11 {
				margin-left: 91.6667%;
			}
		}
	}
}

@include minW(576) {
	.row {
		&--sm {
			&-row {
				flex-direction: row;

				&-reverse {
					flex-direction: row-reverse;
				}
			}

			&-column {
				flex-direction: column;

				&-reverse {
					flex-direction: column-reverse;
				}
			}

			&-wrap-reverse {
				flex-wrap: wrap-reverse;
			}

			&-nowrap {
				flex-wrap: nowrap;
			}

			&-justify-content-start {
				justify-content: flex-start;
			}

			&-justify-content-end {
				justify-content: flex-end;
			}

			&-justify-content-center {
				justify-content: center;
			}

			&-justify-content-between {
				justify-content: space-between;
			}

			&-justify-content-around {
				justify-content: space-around;
			}

			&-justify-content-evenly {
				justify-content: space-evenly;
			}

			&-align-items-start {
				align-items: flex-start;
			}

			&-align-items-end {
				align-items: flex-end;
			}

			&-align-items-center {
				align-items: center;
			}

			&-align-items-baseline {
				align-items: baseline;
			}

			&-align-items-stretch {
				align-items: stretch;
			}

			&-align-content-start {
				align-content: flex-start;
			}

			&-align-content-end {
				align-content: flex-end;
			}

			&-align-content-center {
				align-content: center;
			}

			&-align-content-between {
				align-content: space-between;
			}

			&-align-content-around {
				align-content: space-around;
			}

			&-align-content-stretch {
				align-content: stretch;
			}

			&-align-self-start {
				align-self: flex-start;
			}

			&-align-self-end {
				align-self: flex-end;
			}

			&-align-self-center {
				align-self: center;
			}

			&-align-self-baseline {
				align-self: baseline;
			}

			&-align-self-stretch {
				align-self: stretch;
			}
		}

		&__col {
			&--sm {
				&-auto {
					flex: 0 0 auto;
					width: auto;
				}

				&-1 {
					flex: 0 0 auto;
					width: 8.3333%;
				}

				&-2 {
					flex: 0 0 auto;
					width: 16.6667%;
				}

				&-3 {
					flex: 0 0 auto;
					width: 25%;
				}

				&-4 {
					flex: 0 0 auto;
					width: 33.3333%;
				}

				&-5 {
					flex: 0 0 auto;
					width: 41.6667%;
				}

				&-6 {
					flex: 0 0 auto;
					width: 50%;
				}

				&-7 {
					flex: 0 0 auto;
					width: 58.3333%;
				}

				&-8 {
					flex: 0 0 auto;
					width: 66.6667%;
				}

				&-9 {
					flex: 0 0 auto;
					width: 75%;
				}

				&-10 {
					flex: 0 0 auto;
					width: 83.3333%;
				}

				&-11 {
					flex: 0 0 auto;
					width: 91.6667%;
				}

				&-12 {
					flex: 0 0 auto;
					width: 100%;
				}

				&-offset {
					&-1 {
						margin-left: 8.3333%;
					}

					&-2 {
						margin-left: 16.6667%;
					}

					&-3 {
						margin-left: 25%;
					}

					&-4 {
						margin-left: 33.3333%;
					}

					&-5 {
						margin-left: 41.6667%;
					}

					&-6 {
						margin-left: 50%;
					}

					&-7 {
						margin-left: 58.3333%;
					}

					&-8 {
						margin-left: 66.6667%;
					}

					&-9 {
						margin-left: 75%;
					}

					&-10 {
						margin-left: 83.3333%;
					}

					&-11 {
						margin-left: 91.6667%;
					}
				}
			}
		}
	}
}

@include minW(768) {
	.row {
		&--md {
			&-row {
				flex-direction: row;

				&-reverse {
					flex-direction: row-reverse;
				}
			}

			&-column {
				flex-direction: column;

				&-reverse {
					flex-direction: column-reverse;
				}
			}

			&-wrap-reverse {
				flex-wrap: wrap-reverse;
			}

			&-nowrap {
				flex-wrap: nowrap;
			}

			&-justify-content-start {
				justify-content: flex-start;
			}

			&-justify-content-end {
				justify-content: flex-end;
			}

			&-justify-content-center {
				justify-content: center;
			}

			&-justify-content-between {
				justify-content: space-between;
			}

			&-justify-content-around {
				justify-content: space-around;
			}

			&-justify-content-evenly {
				justify-content: space-evenly;
			}

			&-align-items-start {
				align-items: flex-start;
			}

			&-align-items-end {
				align-items: flex-end;
			}

			&-align-items-center {
				align-items: center;
			}

			&-align-items-baseline {
				align-items: baseline;
			}

			&-align-items-stretch {
				align-items: stretch;
			}

			&-align-content-start {
				align-content: flex-start;
			}

			&-align-content-end {
				align-content: flex-end;
			}

			&-align-content-center {
				align-content: center;
			}

			&-align-content-between {
				align-content: space-between;
			}

			&-align-content-around {
				align-content: space-around;
			}

			&-align-content-stretch {
				align-content: stretch;
			}

			&-align-self-start {
				align-self: flex-start;
			}

			&-align-self-end {
				align-self: flex-end;
			}

			&-align-self-center {
				align-self: center;
			}

			&-align-self-baseline {
				align-self: baseline;
			}

			&-align-self-stretch {
				align-self: stretch;
			}
		}

		&__col {
			&--md {
				&-auto {
					flex: 0 0 auto;
					width: auto;
				}

				&-1 {
					flex: 0 0 auto;
					width: 8.3333%;
				}

				&-2 {
					flex: 0 0 auto;
					width: 16.6667%;
				}

				&-3 {
					flex: 0 0 auto;
					width: 25%;
				}

				&-4 {
					flex: 0 0 auto;
					width: 33.3333%;
				}

				&-5 {
					flex: 0 0 auto;
					width: 41.6667%;
				}

				&-6 {
					flex: 0 0 auto;
					width: 50%;
				}

				&-7 {
					flex: 0 0 auto;
					width: 58.3333%;
				}

				&-8 {
					flex: 0 0 auto;
					width: 66.6667%;
				}

				&-9 {
					flex: 0 0 auto;
					width: 75%;
				}

				&-10 {
					flex: 0 0 auto;
					width: 83.3333%;
				}

				&-11 {
					flex: 0 0 auto;
					width: 91.6667%;
				}

				&-12 {
					flex: 0 0 auto;
					width: 100%;
				}

				&-offset {
					&-1 {
						margin-left: 8.3333%;
					}

					&-2 {
						margin-left: 16.6667%;
					}

					&-3 {
						margin-left: 25%;
					}

					&-4 {
						margin-left: 33.3333%;
					}

					&-5 {
						margin-left: 41.6667%;
					}

					&-6 {
						margin-left: 50%;
					}

					&-7 {
						margin-left: 58.3333%;
					}

					&-8 {
						margin-left: 66.6667%;
					}

					&-9 {
						margin-left: 75%;
					}

					&-10 {
						margin-left: 83.3333%;
					}

					&-11 {
						margin-left: 91.6667%;
					}
				}
			}
		}
	}
}

@include minW(992) {
	.row {
		&--lg {
			&-row {
				flex-direction: row;

				&-reverse {
					flex-direction: row-reverse;
				}
			}

			&-column {
				flex-direction: column;

				&-reverse {
					flex-direction: column-reverse;
				}
			}

			&-wrap-reverse {
				flex-wrap: wrap-reverse;
			}

			&-nowrap {
				flex-wrap: nowrap;
			}

			&-justify-content-start {
				justify-content: flex-start;
			}

			&-justify-content-end {
				justify-content: flex-end;
			}

			&-justify-content-center {
				justify-content: center;
			}

			&-justify-content-between {
				justify-content: space-between;
			}

			&-justify-content-around {
				justify-content: space-around;
			}

			&-justify-content-evenly {
				justify-content: space-evenly;
			}

			&-align-items-start {
				align-items: flex-start;
			}

			&-align-items-end {
				align-items: flex-end;
			}

			&-align-items-center {
				align-items: center;
			}

			&-align-items-baseline {
				align-items: baseline;
			}

			&-align-items-stretch {
				align-items: stretch;
			}

			&-align-content-start {
				align-content: flex-start;
			}

			&-align-content-end {
				align-content: flex-end;
			}

			&-align-content-center {
				align-content: center;
			}

			&-align-content-between {
				align-content: space-between;
			}

			&-align-content-around {
				align-content: space-around;
			}

			&-align-content-stretch {
				align-content: stretch;
			}

			&-align-self-start {
				align-self: flex-start;
			}

			&-align-self-end {
				align-self: flex-end;
			}

			&-align-self-center {
				align-self: center;
			}

			&-align-self-baseline {
				align-self: baseline;
			}

			&-align-self-stretch {
				align-self: stretch;
			}
		}

		&__col {
			&--lg {
				&-auto {
					flex: 0 0 auto;
					width: auto;
				}

				&-1 {
					flex: 0 0 auto;
					width: 8.3333%;
				}

				&-2 {
					flex: 0 0 auto;
					width: 16.6667%;
				}

				&-3 {
					flex: 0 0 auto;
					width: 25%;
				}

				&-4 {
					flex: 0 0 auto;
					width: 33.3333%;
				}

				&-5 {
					flex: 0 0 auto;
					width: 41.6667%;
				}

				&-6 {
					flex: 0 0 auto;
					width: 50%;
				}

				&-7 {
					flex: 0 0 auto;
					width: 58.3333%;
				}

				&-8 {
					flex: 0 0 auto;
					width: 66.6667%;
				}

				&-9 {
					flex: 0 0 auto;
					width: 75%;
				}

				&-10 {
					flex: 0 0 auto;
					width: 83.3333%;
				}

				&-11 {
					flex: 0 0 auto;
					width: 91.6667%;
				}

				&-12 {
					flex: 0 0 auto;
					width: 100%;
				}

				&-offset {
					&-1 {
						margin-left: 8.3333%;
					}

					&-2 {
						margin-left: 16.6667%;
					}

					&-3 {
						margin-left: 25%;
					}

					&-4 {
						margin-left: 33.3333%;
					}

					&-5 {
						margin-left: 41.6667%;
					}

					&-6 {
						margin-left: 50%;
					}

					&-7 {
						margin-left: 58.3333%;
					}

					&-8 {
						margin-left: 66.6667%;
					}

					&-9 {
						margin-left: 75%;
					}

					&-10 {
						margin-left: 83.3333%;
					}

					&-11 {
						margin-left: 91.6667%;
					}
				}
			}
		}
	}
}

@include minW(1200) {
	.row {
		&--xl {
			&-row {
				flex-direction: row;

				&-reverse {
					flex-direction: row-reverse;
				}
			}

			&-column {
				flex-direction: column;

				&-reverse {
					flex-direction: column-reverse;
				}
			}

			&-wrap-reverse {
				flex-wrap: wrap-reverse;
			}

			&-nowrap {
				flex-wrap: nowrap;
			}

			&-justify-content-start {
				justify-content: flex-start;
			}

			&-justify-content-end {
				justify-content: flex-end;
			}

			&-justify-content-center {
				justify-content: center;
			}

			&-justify-content-between {
				justify-content: space-between;
			}

			&-justify-content-around {
				justify-content: space-around;
			}

			&-justify-content-evenly {
				justify-content: space-evenly;
			}

			&-align-items-start {
				align-items: flex-start;
			}

			&-align-items-end {
				align-items: flex-end;
			}

			&-align-items-center {
				align-items: center;
			}

			&-align-items-baseline {
				align-items: baseline;
			}

			&-align-items-stretch {
				align-items: stretch;
			}

			&-align-content-start {
				align-content: flex-start;
			}

			&-align-content-end {
				align-content: flex-end;
			}

			&-align-content-center {
				align-content: center;
			}

			&-align-content-between {
				align-content: space-between;
			}

			&-align-content-around {
				align-content: space-around;
			}

			&-align-content-stretch {
				align-content: stretch;
			}

			&-align-self-start {
				align-self: flex-start;
			}

			&-align-self-end {
				align-self: flex-end;
			}

			&-align-self-center {
				align-self: center;
			}

			&-align-self-baseline {
				align-self: baseline;
			}

			&-align-self-stretch {
				align-self: stretch;
			}
		}

		&__col {
			&--xl {
				&-auto {
					flex: 0 0 auto;
					width: auto;
				}

				&-1 {
					flex: 0 0 auto;
					width: 8.3333%;
				}

				&-2 {
					flex: 0 0 auto;
					width: 16.6667%;
				}

				&-3 {
					flex: 0 0 auto;
					width: 25%;
				}

				&-4 {
					flex: 0 0 auto;
					width: 33.3333%;
				}

				&-5 {
					flex: 0 0 auto;
					width: 41.6667%;
				}

				&-6 {
					flex: 0 0 auto;
					width: 50%;
				}

				&-7 {
					flex: 0 0 auto;
					width: 58.3333%;
				}

				&-8 {
					flex: 0 0 auto;
					width: 66.6667%;
				}

				&-9 {
					flex: 0 0 auto;
					width: 75%;
				}

				&-10 {
					flex: 0 0 auto;
					width: 83.3333%;
				}

				&-11 {
					flex: 0 0 auto;
					width: 91.6667%;
				}

				&-12 {
					flex: 0 0 auto;
					width: 100%;
				}

				&-offset {
					&-1 {
						margin-left: 8.3333%;
					}

					&-2 {
						margin-left: 16.6667%;
					}

					&-3 {
						margin-left: 25%;
					}

					&-4 {
						margin-left: 33.3333%;
					}

					&-5 {
						margin-left: 41.6667%;
					}

					&-6 {
						margin-left: 50%;
					}

					&-7 {
						margin-left: 58.3333%;
					}

					&-8 {
						margin-left: 66.6667%;
					}

					&-9 {
						margin-left: 75%;
					}

					&-10 {
						margin-left: 83.3333%;
					}

					&-11 {
						margin-left: 91.6667%;
					}
				}
			}
		}
	}
}
