.company-history {
	position: relative;
	padding-top: 14.6rem;
	overflow: hidden;

	.history__item .title {
		margin-top: 0;
	}

	&__head {
		text-align: center;
		padding: 13rem 0;

		.title {
			margin-bottom: 0;
		}
	}
}
