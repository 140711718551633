.contact-us {
	position: relative;
	width: 100%;
	min-height: 100vh;
	padding: 20.6rem 0 6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	@include minW(768) {
		padding: 16rem 0 6rem;
	}

	&__bg {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background-color: rgba($black-color, 0.5);
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}

		img,
		video {
			position: absolute;
			min-width: 100%;
			min-height: 100%;
			display: block;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			object-fit: cover;
		}
	}

	&__body {
		position: relative;
		width: 100%;
		height: 100%;
		color: $white-color;
		z-index: 2;

		.intro-copy {
			color: $white-color;
		}
	}

	&__content {
		padding: 0 2rem;

		@include minW(992) {
			max-width: 80rem;
			padding: 0;
		}
	}

	// &__control {
	//   position: absolute;
	//   bottom: 7rem;
	//   left: 2rem;
	//   background-color: transparent;
	//   width: 4.8rem;
	//   height: 4.8rem;
	//   border: 0;
	//   outline: 0;
	//   transition: background-color .3s ease-out;
	//   display: flex;
	//   align-items: center;
	//   justify-content: center;
	//   z-index: 3;

	//   @include minW(768) {
	//     bottom: 10rem;
	//   }

	//   &::before {
	//     content: '';
	//     display: block;
	//     width: 1.2rem;
	//     height: 1.2rem;
	//     background-image: url('../img/icons/play.svg');
	//     background-repeat: no-repeat;
	//     background-position: center;
	//   }

	//   &:focus {
	//     outline: 0;
	//   }

	//   @include hover {
	//     background-color: rgba($black-color, 0.5);
	//   }

	//   &.is-played {
	//     &::before {
	//       background-image: url('../img/icons/pause.svg');
	//     }
	//   }
	// }

	// &__main {
	//   display: flex;
	//   justify-content: center;
	//   width: 100%;
	// }

	// &__body {
	//   position: relative;
	//   display: flex;
	//   align-items: center;
	//   justify-content: center;
	//   width: 100%;
	//   height: 100%;
	//   z-index: 2;
	// }

	// &__content {
	//   padding: 0 2rem;
	//   text-align: left;
	//   display: flex;
	//   flex-direction: column;
	//   justify-content: center;
	//   color: $white-color;
	//   width: 100%;
	//   max-width: 84rem;

	//   .intro-copy {
	//     color: $white-color;
	//     margin-bottom: 4rem;
	//   }
	// }

	// &__logo {
	//   width: 100%;
	//   max-width: 40rem;
	// }

	// &__title {
	//   text-transform: uppercase;

	//   .heading-one {
	//     span {
	//       text-transform: none;
	//       display: block;
	//     }
	//   }
	// }

	// &__desc {
	//   width: 100%;
	//   max-width: 68rem;
	//   margin: 0 auto;

	//   .intro-copy {
	//     margin-bottom: 4rem;
	//   }
	// }

	&__list {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&__item {
		width: 100%;
		margin-bottom: 3rem;
		padding-bottom: 2rem;
		border-bottom: 1px solid $white-color;

		@include minW(992) {
			width: 50%;
			margin-bottom: 2rem;
			border-bottom: 0;
		}

		.title {
			margin-bottom: 5px;
		}
	}

	&__link {
		color: $white-color;
		i {
			margin-right: 1rem;
		}
		&:hover {
			text-decoration: underline;
		}
	}
}
.subed {
	display: none;
}
