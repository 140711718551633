.mfourthree {
	position: relative;
	padding: 11.5rem 0 12.6rem;

	@include minW(992) {
		padding: 11.5rem 0 15.2rem;
	}

	&--reverse {
		.row {
			flex-direction: row-reverse;
		}
	}

	.row {
		align-items: center;
	}

	&__body {
		.img {
			margin-bottom: 2rem;

			@include minW(992) {
				margin-bottom: 0;
			}
		}
	}
}
