.newsletter {
  position: relative;
  overflow: hidden;
  padding: 7rem 0;

  @include minW(992) {
    padding: 12rem 0;
  }

  &__main {
    width: 100%;
    max-width: 88.8rem;
    padding: 0 2rem;
    margin: 0 auto;
  }

  .title {
    text-align: center;
  }
}
