.social-new {
	align-items: center;
	justify-content: center;
	&.btn-group {
		margin-bottom: 10rem;
		@include minW(992) {
			margin-bottom: 10rem;
		}
	}
	margin-bottom: 10rem;
	@include minW(992) {
		margin-bottom: 10rem;
	}
	.social-new__item {
		margin: 0 3rem 2rem;
		width: 100%;
		@include minW(992) {
			padding: 1.2rem 0;
			width: 21vw;
			margin: 0 0.75rem;
		}
	}
	.btn__text {
		img {
			height: 2rem;
			display: inline-block;
			margin-right: 0.7rem;
			/* width: 1.25rem; */
		}
		span {
			font-size: 0.95rem;
		}
	}
}
.image-newconcept {
	margin-top: 10rem;
}
#countdown {
	ul {
		display: flex;
		align-items: center;
		padding-left: 0;
		margin: 3.5rem 0 3rem;
		li {
			list-style: none;
			span {
				display: block;
				font-size: 4rem;
				width: 20vw;
				font-weight: 900;
				margin-bottom: 1rem;
			}
			@media (min-width: 768px) {
				margin: 0 2.5rem;
				font-size: 2.5rem;
				span {
					font-size: 6.5rem;
					width: 7.5vw;
					line-height: 6.5rem;
				}
			}
		}
	}
}
.countdown-holder {
	.title--2 {
		/* font-size: 2.6rem; */
		text-transform: uppercase;
		@media (min-width: 1280px) {
			/* font-size: 6.3rem; */
			white-space: nowrap;
			span {
				display: block;
			}
		}
	}
}
.standard-hero__content .intro-copy-new.intro-copy {
	margin-bottom: 0;
	margin-top: 3rem;
}
.build-your-own.build-newconcept {
	background: url(../img/newconcept/bg-left.png) no-repeat 0 0;
	background-size: 100%;
	padding-top: 19rem;
	@include minW(992) {
		background: url(../img/newconcept/bg-full.jpg) no-repeat 0 0;
		background-size: 100%;
		padding-top: 8rem;
	}

	.row {
		position: relative;
		z-index: 1;
	}
	.mb-only {
		@include minW(992) {
			display: none;
		}
	}
	.logo-small {
		padding: 2rem 2rem 0;
	}
	.product-car {
		padding: 0 2rem;
		margin-top: -3rem;
		@include minW(992) {
			margin-top: -7rem;
			margin-left: -5rem;
			opacity: 0;
			/* padding-bottom: 10rem; */
		}
	}
	.background-car {
		left: 0;
		top: 47.8vw;
		@include minW(992) {
			left: 80%;
			top: 0;
			opacity: 0;
		}
		.background-car__item {
			@include maxW(992) {
				transform: translate(0, 0px) !important;
			}
		}
	}
	.build-your-own__left {
		text-align: center;
		@include minW(992) {
			.title--3 {
				text-align: left;
				padding-left: 8.5rem;
				padding-top: 1.5vw;
				font-size: 3.3rem;
				line-height: 4rem;
			}
		}
		@include minW(1336) {
			.title--3 {
				padding-left: 7vw;
				padding-top: 0.5vw;
				font-size: 3.3rem;
				line-height: 4rem;
			}
		}
		@include minW(1600) {
			.title--3 {
				padding-left: 7vw;
				padding-top: 4.2vw;
				font-size: 3.3rem;
				line-height: 4rem;
			}
		}
		@include minW(1700) {
			.title--3 {
				padding-left: 6.8vw;
				padding-top: 4.8vw;
				font-size: 3.3rem;
				line-height: 4rem;
			}
		}
		@include minW(1920) {
			.title--3 {
				padding-left: 14.5rem;
				padding-top: 11rem;
				font-size: 3.3rem;
				line-height: 4rem;
			}
		}
		.title--3 span {
			display: block;
		}
	}
	.build-your-own__right {
		text-align: justify;
	}
}
.test-drive.test-drive-newconcept {
	padding-top: 6rem;
	@include minW(992) {
		/* padding-top: 0; */
	}
	.form {
		padding-top: 2rem;
	}
	h2 {
		text-align: center;
	}
}
.discovery-newconcept {
	.discovery__thumb {
		@include maxW(992) {
			width: 130%;
			margin-left: -25%;
			min-height: 33rem;
		}
	}
	.discovery__thumb .img {
		object-fit: contain;
		@include minW(992) {
			object-fit: cover;
		}
	}
	.btn {
		@include minW(992) {
			padding: 1.2rem 3rem;
			font-size: 1.2rem;
		}
	}
	.discovery__content .label-small {
		@include minW(992) {
			margin-bottom: 2rem;
		}
	}
}
/* .auto-slider {
	@include maxW(992) {
		&.banner-hero .standard-hero {
			height: auto !important;
			min-height: 134vw;
		}
		.standard-hero__bg {
			top: 10rem;
		}
		.standard-hero__bg img {
			object-fit: cover;
		}
	}
} */
.slick__item.align-center {
	.btn {
		display: block;
		margin: 0 auto;
	}
	.standard-hero--right .standard-hero__content {
		align-items: center;
		width: 100%;
		@include minW(992) {
			align-items: flex-end;
			width: auto;
		}
	}
}
.btn-group__item .btn.btn--xfc {
	@include minW(992) {
		min-width: 25vw;
	}
}
// #interior .fifty-fifty__title,
// #performance .fifty-fifty__title,
// #exterior .fifty-fifty__title {
// 	@include minW(992) {
// 		white-space: nowrap;
// 	}
// }
.content-block {
	&.activation-route {
		.note {
			margin: 0 2rem;
			@include minW(992) {
				display: none;
			}
		}

		.region {
			margin-bottom: 1rem;
			@include minW(992) {
				/* display: none; */
			}
		}
		.region__list {
			display: none;
			@include minW(992) {
				display: flex;
				justify-content: center;
				margin-bottom: 2rem;
			}
		}
		.region__select {
			.select {
				max-width: 100%;
			}
			.select2-container {
				width: 100% !important;
				/* @include minW(992) {
					width: 50% !important;
				} */
			}
			padding: 0 2rem;
			margin-top: 8rem;
		}
		.region__location {
			padding: 0 2rem;
			@include minW(992) {
				display: none;
			}
		}

		margin: 8rem 0;
		position: relative;
		.content-block__title {
			text-align: center;
		}
		.content-block__content {
			.list {
				li {
					list-style: none;
					/* position: absolute; */
					@include maxW(992) {
						position: static;
						margin-bottom: 1.5rem;
					}
					cursor: pointer;
					.icon {
						width: 1.0419rem;
						height: 1.6669rem;
						position: absolute;
						display: none;
						@include minW(992) {
							position: absolute;
							width: 1.2rem;
							height: 1.8rem;
							display: block;
						}
						background: url(../img/concept-cars/i-grey.svg) no-repeat 0 0;
						background-size: 100%;
						text-indent: -9999em;
						list-style-type: none;
					}
					&.hn {
						.icon {
							top: 11.8vw;
							left: 29.74vw;
							@include minW(992) {
								top: 4.6vw;
								left: 11.6vw;
							}
						}
					}
					&.hd {
						.icon {
							top: 13.1vw;
							left: 34.2vw;
							@include minW(992) {
								top: 5.1vw;
								left: 13.2vw;
							}
						}
					}
					&.bn {
						.icon {
							top: 9.4vw;
							left: 33.8vw;
							@include minW(992) {
								top: 3.6vw;
								left: 13vw;
							}
						}
					}
					&.qn {
						.icon {
							top: 12.1vw;
							left: 40.3vw;
							@include minW(992) {
								top: 4.7vw;
								left: 15.5vw;
							}
						}
					}
					&.hp {
						.icon {
							top: 14.7vw;
							left: 37.35vw;
							@include minW(992) {
								top: 5.7vw;
								left: 14.35vw;
							}
						}
					}
					&.th {
						.icon {
							top: 19.3vw;
							left: 28.8vw;
							@include minW(992) {
								top: 7.3vw;
								left: 11vw;
							}
						}
					}
					&.na {
						/* display: none !important; */
						.icon {
							top: 23.5vw;
							left: 31vw;
							@include minW(992) {
								top: 9vw;
								left: 12vw;
							}
						}
					}
					&.dn {
						.icon {
							top: 37.1vw;
							left: 56.1vw;
							@include minW(992) {
								top: 14.1vw;
								left: 21.6vw;
							}
						}
					}
					&.bd {
						.icon {
							top: 50.3vw;
							left: 65.8vw;
							@include minW(992) {
								top: 19.3vw;
								left: 25.1vw;
							}
						}
					}
					&.bt {
						.icon {
							top: 74.7vw;
							left: 62.8vw;
							@include minW(992) {
								top: 28.7vw;
								left: 24.1vw;
							}
						}
					}
					&.dnai {
						.icon {
							top: 75vw;
							left: 42.6vw;
							@include minW(992) {
								top: 28.8vw;
								left: 16.3vw;
							}
						}
					}
					&.bduong {
						.icon {
							top: 77vw;
							left: 45.3vw;
							@include minW(992) {
								top: 29.8vw;
								left: 17.3vw;
							}
						}
					}
					&.ag {
						.icon {
							top: 87.6vw;
							left: 26.6vw;
							@include minW(992) {
								top: 33.6vw;
								left: 10.3vw;
							}
						}
					}
					&.ctho {
						.icon {
							top: 89.5vw;
							left: 30.1vw;
							@include minW(992) {
								top: 34.6vw;
								left: 11.6vw;
							}
						}
					}
					&.active {
						display: block;
						z-index: 1;
						.icon {
							background: url(../img/concept-cars/i-red.svg) no-repeat 0 0;
							background-size: 100%;
							transform: scale(1.5);
							@include minW(992) {
								transform: scale(1.7);
								position: absolute;
							}
							z-index: 1;
							@include minW(992) {
								/* position: static; */
							}
						}
					}
				}
			}
			.content-block__desc {
				position: relative;
				/* min-height: 124vw; */
				width: 100%;
				/* max-width: 1107px; */
				/* padding-top: 124vw; */
				@include minW(992) {
					margin-top: -1vw;
					width: 38.39vw;
					/* min-height */
					min-height: 50vw;
					background: url(../img/concept-cars/map.png) no-repeat 0 0;
					background-size: 100%;
					margin-left: 53.6vw;
					padding-top: 0;
				}
				.content {
					background: #f6f6f6;
					box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
					width: 96%;
					max-width: 430px;

					@include minW(992) {
						display: none;
						width: 25.39vw;
						position: absolute;
						/* left: -25vw;
						top: -2.5vw; */
						left: -32vw;
						top: 2.5vw;
						z-index: 3;
						max-height: 43vw;
						overflow: scroll;
					}
					.event_name {
						width: 100%;
						justify-content: flex-start;
						@include minW(992) {
							/* padding-left: 2rem; */
							font-size: 1.45rem;
							padding: 0.5rem 1.5rem;
						}
						@include maxW(991) {
							font-size: 1.5rem;
						}
					}
					.item {
						border-bottom: 1px dashed #000000;
						padding: 1.5rem;
						display: none;
						@include minW(992) {
							display: block;
						}
						.dealer_name {
							text-transform: uppercase;
							margin-bottom: 1.2rem;
							@include minW(992) {
								/* font-size: 1.2rem; */
								margin-bottom: 0.5rem;
								font-size: 1.8rem;
								span {
									display: block;
								}
							}
						}
						.dealer_phone {
							margin-bottom: 0.5rem;
							span,
							a {
								color: red;
							}
						}
						.btn--black.btn--xfc,
						.btn--black.showmor {
							color: #000;
							background: transparent;
							padding: 0.5rem 1.5rem;
							width: 100%;
						}
						.btns-register {
							display: none;
							&.is-active {
								display: block;
							}
							.btn {
								margin-top: 2rem;
								padding: 0.5rem 0;
							}
						}
						&:last-child {
							border-bottom: none;
						}
					}
				}
				.active {
					.content {
						display: block;
					}
				}
				.right {
					.content {
						@include minW(992) {
							position: absolute;
							left: 0.4vw;
							top: 2vw;
						}
					}
				}
				.right2 {
					.content {
						@include minW(992) {
							position: absolute;
							left: 0.4vw;
							top: 2vw;
						}
					}
				}
			}
		}
	}
}
