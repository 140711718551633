.hero {
	position: relative;
	overflow: hidden;
	//padding: 26rem 0 16rem;
	padding: 10rem 0;
	color: $black-color;

	@include minW(992) {
		padding: 13rem 0;
	}
	&--2 {
		padding: 0;
		@include minW(992) {
			padding: 0;
		}
	}
	&.detail-page {
		padding: 6rem 0 1.5rem;

		.intro-body {
			color: $dark-grey-color;
		}
	}

	&--bg {
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		color: $white-color;

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(#000, 0.5);
		}
	}

	&--mb {
		margin-bottom: 8rem;
	}

	&--top {
		padding-top: 27.6rem;
	}

	&--small {
		padding: 6rem 0 3rem;
	}

	&--black {
		background-color: $black-color;
		color: $white-color;
	}

	&__main {
		position: relative;
		z-index: 2;
	}

	&__body {
		text-align: center;
		max-width: 132rem;
		padding: 0 2rem;
		margin: 0 auto;

		.title {
			margin-bottom: 3rem;
		}
	}
}
