.toasts {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 9999;

	&__dialog {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		max-width: 320px;
		text-align: center;
	}

	&__main {
		background-color: $white-color;
		padding: 3rem;
		width: 100%;
		font-size: 1.2rem;
		line-height: 1.2;
	}

	&__foot {
		.btn {
			margin: 0 0.5rem;
		}
	}
}
