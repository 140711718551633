.editor-car {
	position: relative;
	overflow: hidden;
	padding: 4rem 0;

	&--odds {
		padding-top: 12rem;

		.row {
			&__col:nth-child(2n+2) {
				.editor-car {
					&__item {
						padding-top: 18rem;
					}
				}
			}
		}
	}

	&__item {
		position: relative;
	}

	&__thumb {
		margin-bottom: 2rem;
	}
}
