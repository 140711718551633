@keyframes m {
	0% {
		background-color: #fff;
		transform: skewX(-30deg) translateX(-84%)
	}

	50% {
		background-color: #fff;
		transform: skewX(-30deg) translateX(-100%)
	}

	to {
		background-color: #fff;
		transform: skewX(0deg) translateX(-100%)
	}
}

@keyframes n {
	0% {
		background-color: #fff;
		transform: skewX(0deg) translateX(-100%)
	}

	50% {
		background-color: #fff;
		transform: skewX(-30deg) translateX(-100%)
	}

	to {
		background-color: #fff;
		transform: skewX(-30deg) translateX(-84%)
	}
}

@keyframes q {
	0% {
		transform: rotate(0deg)
	}

	50% {
		transform: rotate(-.4deg)
	}

	to {
		transform: rotate(0deg)
	}
}

@keyframes o {
	0% {
		transform: translateX(170%)
	}

	to {
		transform: translateX(0)
	}
}

@keyframes p {
	0% {
		transform: rotate(0)
	}

	to {
		transform: rotate(-1turn)
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes flash {

	from,
	50%,
	to {
		opacity: 1;
	}

	25%,
	75% {
		opacity: 0;
	}
}


@keyframes arrow-left {
	0% {
		transform: translateX(0) translateY(-80%);
	}

	25% {
		transform: translateX(-1rem) translateY(-80%);
	}

	50% {
		transform: translateX(0) translateY(-80%);
	}
}

@keyframes arrow-right {
	0% {
		transform: translateX(0) translateY(-80%);
	}

	25% {
		transform: translateX(1rem) translateY(-80%);
	}

	50% {
		transform: translateX(0) translateY(-80%);
	}
}
