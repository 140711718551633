.loan-package {
	&__item {
		position: relative;
		overflow: hidden;
		padding: 4rem 0;

		&__card {
			margin-bottom: 5rem;
		}
	}

	&__card {
		margin-bottom: 4rem;
	}

	&__fixed {
		color: #ED0000;
		font-size: 2rem;
		line-height: 4.3rem;
		font-weight: 500;
	}
}

.standard-package {
	position: relative;
	overflow: hidden;
	padding: 4rem 0;

	&__card {
		margin-bottom: 5rem;
	}

	&__footer {
		border-bottom: 1px solid $black-color;
	}
}

.monthly-payments {
	text-align: center;
	margin-bottom: 5rem;

	h3 {
		font-size: 1.8rem;
		line-height: 2.5rem;
		font-weight: 500;
		text-transform: uppercase;
	}

	&__text {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 1.6rem;
		line-height: 2.8rem;
		font-weight: 500;
		text-transform: uppercase;

		p {
			text-align: left;

			&:last-child {
				text-align: right;
			}

			span {
				display: block;
			}
		}
	}
}
