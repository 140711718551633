.service-interval {
	position: relative;
	margin-bottom: 6rem;
	overflow: hidden;

	&__main {
		width: 100%;
		margin: 0 auto;
		padding: 0 2rem;

		@include minW(992) {
			max-width: 83.3333%;
		}
	}

	&__head {
		text-align: center;
		padding: 3.5rem 0 11.5rem;

		@include minW(992) {
			padding: 16rem 0 13rem;
		}
	}

	.form {
		&__group {

			&--button {
				text-align: center;
			}
		}
	}
}

.service-interval-result {
	position: relative;
	overflow: hidden;

	&__main {
		width: 100%;
		margin: 0 auto;
		padding: 0 2rem;

		@include minW(992) {
			max-width: 83.3333%;
		}
	}

	&__head {
		margin-bottom: 4rem;

		p {
			font-size: 1.6rem;
			line-height: 2.8rem;
			color: $dark-grey-color;
			margin-bottom: 0;

			span {
				display: inline-block;
				min-width: 17rem;
			}
		}
	}

	&__body {
		margin-bottom: 6rem;
		overflow-x: auto;

		table {
			border-collapse: collapse;
			width: 100%;
			max-width: 100%;
			min-width: 64rem;

			th,
			td {
				padding: 2rem;
				border: 1px solid $white-color;
			}

			thead,
			tfoot {
				background-color: $black-color;
				color: $white-color;
				font-size: 1.4rem;
				line-height: 1.8rem;
				letter-spacing: 0.2px;
				text-transform: uppercase;
			}

			tbody {
				color: $dark-grey-color;

				tr {
					&:nth-of-type(odd) {
						td {
							background-color: $grey-color;
						}
					}

					td {
						text-decoration: underline;

						&[rowspan] {
							background-color: $grey-color;
							text-decoration: none;
						}

						&:last-child {
							text-decoration: none;
						}
					}
				}

				// td:first-child {
				// 	text-decoration: underline;
				// }
			}

			tfoot {
				background-color: #5A646E;
			}
		}
	}

	&__foot {
		color: $dark-grey-color;
	}
}
