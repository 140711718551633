.news-tabbed {
	position: relative;
	overflow: hidden;
	padding: 4rem 0;

	&__head {
		margin-bottom: 4rem;
	}

	&__foot {
		text-align: center;
	}
}
