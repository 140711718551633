.title {
	font-size: 3.6rem;
	line-height: 4.4rem;
	font-weight: 700;

	@include minW(768) {
		font-size: 8.4rem;
		line-height: 9.2rem;
	}

	&--1 {
		font-size: 3.2rem;
		line-height: 3.8rem;

		@include minW(768) {
			font-size: 6.3rem;
			line-height: 7.2rem;
		}
	}

	&--2 {
		font-size: 2.8rem;
		line-height: 3.5rem;

		@include minW(768) {
			font-size: 4.2rem;
			line-height: 4.7rem;
		}
	}

	&--3 {
		font-size: 2.4rem;
		line-height: 3.1rem;

		@include minW(768) {
			font-size: 3.6rem;
			line-height: 4.8rem;
		}
	}

	&--4 {
		font-size: 2.1rem;
		line-height: 2.8rem;

		@include minW(768) {
			font-size: 2.4rem;
			line-height: 3.2rem;
		}
	}

	&--5 {
		font-size: 2.1rem;
		line-height: 2.7rem;
	}

	&--6 {
		font-size: 2rem;
		line-height: 2.7rem;
	}

	&--400 {
		font-weight: 400;
	}

	&--500 {
		font-weight: 500;
	}

	&--700 {
		font-weight: 700;
	}

	&--uppercase {
		text-transform: uppercase;
	}

	&--black {
		color: $black-color;
	}

	&--dark-grey {
		color: $dark-grey-color;
	}

	&--cold-grey {
		color: $cold-grey-color;
	}

	&--grey {
		color: $grey-color;
	}

	span,
	&__text {
		display: block;
		text-transform: none;
	}
}
