.years-sticky {
  position: relative;

  &__body {
    position: relative;
    display: flex;
    align-items: center;
    box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.31);
    overflow-x: auto;

    @include minW(992) {
      justify-content: center;
    }
  }

  &__item {
    padding: 1rem 0;
    margin: 0 3.5rem;
    font-size: 1.4rem;
    min-width: 10rem;

    &.is-active {
      font-weight: 700;
      font-size: 2.4rem;
      border-top: 2px solid $red-color;
      min-width: 14rem;
    }
  }

  &__text {
    cursor: pointer;
  }
}
