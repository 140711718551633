.standard-hero {
	position: relative;
	width: 100%;
	min-height: 100vh;
	padding: 16rem 0 6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	@include minW(768) {
		padding: 16rem 0 6rem;
	}

	&--right {
		.standard-hero {
			&__logo {
				margin-bottom: 2rem;
			}

			&__body {
				position: relative;
				width: 100%;
				height: 100%;
				color: #fff;
				text-align: right;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				z-index: 2;

				@include minW(992) {
					max-width: 83.33333%;
					padding: 0;
				}
			}

			&__content {
				font-size: 1.8rem;
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				text-align: right;

				@include minW(992) {
					width: 50%;
					font-size: 2.1rem;
					line-height: 1.7;
				}

				.intro-copy {
					margin-bottom: 0;
				}
			}
		}
	}

	&__bg {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background-color: rgba($black-color, 0.5);
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}

		img,
		video {
			position: absolute;
			min-width: 100%;
			min-height: 100%;
			display: block;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			object-fit: cover;
		}
	}

	&__desktop {
		/* display: none; */

		@include minW(992) {
			display: block;
		}
	}

	&__mobile {
		display: block;

		@include minW(992) {
			display: none;
		}

		/* img {
			position: relative;
			min-height: unset;
			top: auto;
			left: auto;
			transform: none;
			object-fit: unset;
		} */
	}

	&__control {
		position: absolute;
		bottom: 7rem;
		left: 2rem;
		background-color: transparent;
		width: 4.8rem;
		height: 4.8rem;
		border: 0;
		outline: 0;
		transition: background-color 0.3s ease-out;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 3;

		@include minW(768) {
			bottom: 10rem;
		}

		&::before {
			content: '';
			display: block;
			width: 1.2rem;
			height: 1.2rem;
			background-image: url('../img/icons/play.svg');
			background-repeat: no-repeat;
			background-position: center;
		}

		&:focus {
			outline: 0;
		}

		@include hover {
			background-color: rgba($black-color, 0.5);
		}

		&.is-played {
			&::before {
				background-image: url('../img/icons/pause.svg');
			}
		}
	}

	&__main {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	&__body {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		z-index: 2;
	}

	&__content {
		padding: 0 2rem;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: $white-color;
		width: 100%;

		@include minW(768) {
			max-width: 80%;
		}

		@include minW(992) {
			max-width: 90%;
		}

		@include minW(1200) {
			max-width: 80%;
		}

		.intro-copy {
			color: $white-color;
			margin-bottom: 4rem;
		}
	}

	&__button {
		width: 100%;

		@include minW(1200) {
			max-width: 90%;
		}

		@include minW(1400) {
			max-width: 66.66666%;
		}

		.btn {
			display: block;
			width: 100%;
			margin-bottom: 16px;
			padding: 1.2rem 1.6rem;
		}
	}

	&__logo {
		width: 100%;
		max-width: 36rem;
	}

	&__title {
		text-transform: uppercase;

		.heading-one {
			span {
				text-transform: none;
				display: block;
			}
		}
	}

	&__desc {
		width: 100%;
		max-width: 68rem;
		margin: 0 auto;

		.intro-copy {
			margin-bottom: 4rem;
		}
	}
}
