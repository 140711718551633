.banner-hero {
	position: relative;
	overflow: hidden;

	.slick {
		&-arrow {
			position: absolute;
			top: 50%;
			z-index: 2;
		}

		&-prev {
			left: 5rem;
			background-image: url('../img/icons/arrow-left-white.svg');
			animation: arrow-left 1.5s infinite;

			@include hover {
				background-image: url('../img/icons/arrow-left-red.svg');
			}
		}

		&-next {
			right: 5rem;
			background-image: url('../img/icons/arrow-right-white.svg');
			animation: arrow-right 1.5s infinite;

			@include hover {
				background-image: url('../img/icons/arrow-right-red.svg');
			}
		}
	}

	.slick-track {
		display: flex !important;
	}

	.slick-slide {
		height: inherit !important;
	}

	.standard-hero {
		height: 100% !important;
	}

	&.financial-slider {
		.standard-hero {
			@include maxW(991) {
				min-height: 1px;
				height: auto !important;
				padding: 0;
			}

			&__bg {
				@include maxW(991) {
					position: relative;
					top: auto;
					left: auto;
					height: auto;
				}
			}
			&__mobile {
				img {
					position: relative;
					min-height: unset;
					top: auto;
					left: auto;
					transform: none;
					object-fit: unset;
				}
			}
		}
	}
}
.banner-xpander {
	margin-top: 10rem;
	@include minW(991) {
	}
}
