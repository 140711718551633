.configurator {
	position: relative;
	width: 100%;
	overflow: hidden;
	z-index: 97;
	opacity: 0;
	transition: all 0.3s ease-out;

	&.is-show {
		opacity: 1;
	}

	&.is-active {
		z-index: 1;

		.configurator {
			&__main {
				min-width: unset;
				padding-bottom: 0;
				flex-basis: 100%;
				max-width: 100%;
			}
		}

		.summary {
			position: relative;

			&__head {
				display: none;
			}

			&__thumb {
				display: none;
			}

			&__body {
				max-height: none;
				overflow-y: unset;
			}

			&__info {
				flex-basis: 100%;
				max-width: 100%;
				margin-bottom: 4rem;

				@include minW(992) {
					margin-left: 8.3333%;
					flex-basis: 41.66667%;
					max-width: 41.66667%;
					margin-bottom: 0;
				}
			}

			&__accessory {
				flex-basis: 100%;
				max-width: 100%;

				@include minW(992) {
					flex-basis: 33.33333%;
					max-width: 33.33333%;
					padding: 0 2rem;
				}
			}

			&__noted {
				display: block;
			}
		}
	}

	&__bg {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		overflow: hidden;
		height: 22rem;

		@include minW(768) {
			height: 29rem;
		}

		@include minW(900) {
			height: 40rem;
		}
	}

	&__main {
		position: relative;
		width: 100%;
		padding-top: 10rem;
		min-height: 100vh;
		padding-bottom: 7.2rem;

	}

	&__body {
		.car-degrees:first-child {
			opacity: 1;
			z-index: 2;
		}
	}

	.test-drive {
		display: none;
	}
}

.progress {
	white-space: nowrap;
	position: relative;
	overflow-x: auto;
	overflow-y: hidden;
	z-index: 2;

	&__main {
		position: relative;
		margin: 2rem 2rem 5rem;
	}

	&__item {
		position: relative;
		min-height: 5rem;
		display: none;

		&.is-active {
			display: flex;
		}

		&--accessory {
			.progress {
				&__back {
					flex-grow: 1;
				}

				&__color {
					position: relative;
					flex-grow: 3;
					background-color: #fff;

					&::before {
						content: "";
						width: 0;
						height: 0;
						top: 0;
						border-top: 25px solid transparent;
						border-bottom: 25px solid transparent;
						position: absolute;
						opacity: 1;
						border-right: 21px solid #fff;
						left: -21px;
						animation: e .5s ease-out;
					}

					.progress-step {
						justify-content: center;
					}
				}
			}
		}
	}

	&__back {
		flex-basis: 10%;
		display: flex;
		align-items: center;
		background-color: #eee;
		padding: 0 4rem 0 2rem;
	}

	&__nav,
	&__next {
		display: flex;
		align-items: center;
		flex-basis: 45%;
	}

	&__nav {
		position: relative;
		display: flex;
		justify-content: flex-end;
		padding-left: 2rem;
		background-color: #eee;

		&::after {
			content: "";
			width: 0;
			height: 0;
			top: 0;
			border-top: 25px solid transparent;
			border-bottom: 25px solid transparent;
			position: absolute;
			border-left: 21px solid #eee;
			right: -21px;
			transition: right 1s ease-out;
			opacity: 1;
		}
	}

	&__next {
		padding-left: 3rem;
		padding-right: 2rem;
		background-color: $white-color;
	}

	&__link {
		text-decoration: none;
		color: #000;
		text-transform: uppercase;
		font-size: 1.2rem;
		line-height: 2.2rem;
		letter-spacing: 2px;
		font-weight: 700;
		padding: 0 0 2px;
		border-bottom: 2px solid #000;
		display: inline;
		cursor: pointer;
		transition: all .1s ease-in-out;

		@include hover {
			color: $red-color;
			border-color: $red-color;
		}
	}
}

.progress-step {
	position: relative;
	display: flex;
	justify-content: flex-end;
	margin-bottom: 0;

	&__item {
		position: relative;
		margin: 0 2rem 0 9rem;
		padding: 1.2rem 0;
		color: #5a646e;

		&.is-done {
			cursor: pointer;
		}

		&.is-current {
			&::after {
				border-bottom-color: #ed0000;
			}
		}

		&.is-active {
			color: #000;

			&::before {
				background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='15' viewBox='0 0 15 15' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M7.5 15a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15zm0-1.5a6 6 0 1 0 0-12 6 6 0 0 0 0 12z' id='a'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cuse fill='%23FFF' fill-rule='nonzero' xlink:href='%23a'/%3E%3Cg mask='url(%23b)' fill='%235C666F'%3E%3Cpath d='M0 0h15v15H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
				transform: translateY(-50%);
			}

			@include hover {
				&::before {
					background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='15' viewBox='0 0 15 15' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M7.5 15a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15zm2.463-9.83L6.234 8.76 5.037 7.607a.7.7 0 0 0-.98 0 .664.664 0 0 0 0 .974l1.687 1.625c.141.136.297.2.49.2.194 0 .35-.064.49-.2l4.22-4.063a.664.664 0 0 0 0-.974.7.7 0 0 0-.981 0z' id='a'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cuse fill='%23FFF' fill-rule='nonzero' xlink:href='%23a'/%3E%3Cg mask='url(%23b)' fill='%23000'%3E%3Cpath d='M0 0h15v15H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
				}
			}
		}

		&::before {
			content: "";
			position: absolute;
			width: 15px;
			height: 16px;
			left: -40px;
			background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='15' viewBox='0 0 15 15' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath d='M7.5 15a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15zm2.463-9.83L6.234 8.76 5.037 7.607a.7.7 0 0 0-.98 0 .664.664 0 0 0 0 .974l1.687 1.625c.141.136.297.2.49.2.194 0 .35-.064.49-.2l4.22-4.063a.664.664 0 0 0 0-.974.7.7 0 0 0-.981 0z' id='a'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cuse fill='%23FFF' fill-rule='nonzero' xlink:href='%23a'/%3E%3Cg mask='url(%23b)' fill='%235C666F'%3E%3Cpath d='M0 0h15v15H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
			background-repeat: no-repeat;
			background-position: 0;
			top: 50%;
			transform: translateY(-50%);
			padding-right: 16px;
		}

		&::after {
			content: "";
			position: absolute;
			width: calc(100% + 16px);
			height: 3px;
			background-color: transparent;
			bottom: 0;
			left: -16px;
			border-bottom: 2px solid transparent;
			transition: border-color .3s ease-out;
		}
	}
}

.variant-box {
	&__item {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__price {
		margin-bottom: 0;
	}

	&__title {
		margin-bottom: 4rem;
	}
}

.summary {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 2;

	&.is-sticky {
		position: fixed;
	}

	&__head {
		padding: 1.2rem 2rem;

		.row {
			align-items: center;

			&__col {
				&:nth-child(2) {
					display: none;

					@include minW(992) {
						display: block;
					}
				}
			}
		}

		.btn {
			&--summary {
				display: none;
			}
		}
	}

	&__toggle {
		text-decoration: none;
		color: #000;
		text-transform: uppercase;
		text-align: center;
		font-size: 1.4rem;
		line-height: 1.8rem;
		letter-spacing: 3px;
		font-weight: 700;
		padding: 0;
		display: flex;
		align-items: center;
		cursor: pointer;
		margin-bottom: 0;

		&::before {
			content: "";
			display: inline-block;
			height: 24px;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: contain;
			margin-right: 12px;
			max-width: 12px;
			transform: rotate(0deg);
			transition: transform .3s ease-out;
			background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cpath id='a' fill='%235A646E' d='M12.5 13.563L6.762 8 5 9.712 12.5 17 20 9.712 18.237 8z'/%3E%3C/defs%3E%3Cuse fill-rule='evenodd' xlink:href='%23a'/%3E%3C/svg%3E");
			width: 24px;
		}
	}

	&__short {
		font-size: 2.1rem;
		line-height: 2.5rem;
		font-weight: 700;
		color: #000;
		letter-spacing: 2px;
		margin-bottom: 0;

		span {
			font-weight: 400;
		}
	}

	&__button {
		display: flex;
		justify-content: flex-end;
	}

	&__body {
		position: relative;
		overflow: hidden;
		max-height: 0;
		transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
	}

	&__content {
		padding: 4.5rem 2rem 7rem;
	}

	&__item {
		position: relative;
		background-color: #f7f7f7;
		display: none;

		&:first-child {
			display: flex;
			justify-content: flex-end;
			background-color: #f7f7f7;
			box-shadow: 0 2px 4px 0 #e9e9e9;

			.summary {
				&__head {
					width: 100%;
					display: flex;
					justify-content: flex-end;

					.btn {
						width: 100%;

						@include minW(992) {
							width: auto;
						}
					}
				}
			}
		}


		&--accessory {
			.summary {
				&__head {
					box-shadow: 0 2px 4px 0 #e9e9e9;
				}
			}
		}

		&.is-active {
			.summary {
				&__body {
					max-height: calc(100vh - 16.8rem);
					overflow-y: auto;
					transition: max-height 1s ease-in-out;
				}
			}
		}
	}

	&__label {
		font-size: 1.2rem;
		line-height: 1.6rem;
		letter-spacing: 2px;
		font-weight: 500;
		text-transform: uppercase;
		color: #5a646e;
	}

	&__title {
		font-weight: 700;
		font-size: 2.8rem;
		line-height: 3.2rem;
		letter-spacing: 0;
		margin-bottom: 3rem;

		@include minW(992) {
			font-size: 4.2rem;
			line-height: 4.6rem;
		}
	}

	&__copy {
		border: none;
		background-color: #fff;
		display: flex;
		padding: 8px 12px;
		margin-bottom: 1.6rem;
		overflow: hidden;

		input {
			font-size: 1.6rem;
			line-height: 2.8rem;
			letter-spacing: 0;
			color: #5a646e;
			border: 0;
			background-color: transparent;
			flex-grow: 1;
			padding-right: 8px;
			min-width: 0;

			&:focus {
				outline: 0;
			}
		}

		button {
			background: none;
			text-decoration: none;
			color: #000;
			text-transform: uppercase;
			font-size: 14px;
			line-height: 28px;
			letter-spacing: 3px;
			font-weight: 700;
			padding: 0;
			border-bottom: 2px solid #000;
			display: inline;
			cursor: pointer;
			transition: all .1s ease-in-out;
			border: 0;

			&:focus {
				outline: 0;
			}
		}
	}

	&__row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -2rem;
	}

	&__thumb {
		flex-basis: 100%;
		max-width: 100%;
		padding: 0 2rem;

		@include minW(992) {
			flex-basis: 33.33333%;
			max-width: 33.33333%;
		}
	}

	&__info {
		flex-basis: 100%;
		max-width: 100%;
		padding: 0 2rem;

		@include minW(992) {
			flex-basis: 33.33333%;
			max-width: 33.33333%;
		}
	}

	&__accessory {
		flex-basis: 100%;
		max-width: 100%;
		padding: 0 2rem;

		@include minW(992) {
			flex-basis: 33.33333%;
			max-width: 33.33333%;
		}

		.collapse {
			&__item {
				border: 0;
				border-bottom: 1px solid #000;

				&.is-show {
					.collapse {
						&__head {
							&::after {
								top: 2.5rem;
							}
						}
					}
				}
			}

			&__title {
				width: 100%;
				font-size: 2rem;
				line-height: 2.4rem;
				padding: 1.5rem 3rem 1.5rem 0;
				text-transform: none;
				display: flex;
				align-items: center;
				position: relative;
				justify-content: space-between;

				span {
					&:last-child {
						color: #5c666f;
						font-size: 1.4rem;
						line-height: 2rem;
					}
				}
			}

			&__head {
				border: 0;
				padding: 0;

				&::after {
					top: 2rem;
				}
			}

			&__content {
				padding: 0 0 2rem;
			}
		}
	}

	&__noted {
		margin-top: 3rem;
		display: none;
	}

	&__buttons {

		.btn {
			width: 100%;
			margin-bottom: 1.6rem;
		}
	}

	&__description {
		font-size: 1.6rem;
		line-height: 2rem;
		font-weight: 500;
		letter-spacing: 0;
		color: #000;
	}

	&__total {
		display: none;
		font-weight: 400;
		font-size: 2rem;
		line-height: 2.5rem;
	}

	.collapse {
		&__item {
			opacity: 0;

			&.is-active {
				opacity: 1;
			}
		}
	}
}

.choose {
	&__row {
		margin: 0 -2rem;

		@media screen and (min-width: 992px) {
			display: flex;
		}
	}

	&__model,
	&__variant {
		flex: 0 0 auto;
		padding: 0 2rem;
	}

	&__model {
		width: 100%;

		@media screen and (min-width: 992px) {
			width: 50%;
		}

		@media screen and (max-height: 640px) {
			width: 45%;
		}

		@media screen and (max-height: 580px) {
			width: 40%;
		}
	}

	&__variant {
		width: 100%;

		@media screen and (min-width: 992px) {
			width: 50%;
		}

		@media screen and (max-height: 640px) {
			width: 55%;
		}

		@media screen and (max-height: 580px) {
			width: 60%;
		}
	}

	&__item {
		display: none;
		padding-bottom: 3rem;

		&:first-child {
			display: block;
		}

		&--fabric {
			.row {
				align-items: flex-start;
			}
		}

		&--accessory,
		&--fabric {
			.row {
				margin: 0 -5px;

				@include minW(992) {
					margin: 0 -2rem;
				}

				&__col {
					padding: 0 5px;
					margin-bottom: 1rem !important;

					@include minW(992) {
						padding: 0 2rem;
						margin-bottom: 4rem !important;
					}
				}
			}

			.variant-box {
				&__item {
					.title {
						font-size: 1.6rem;
						line-height: 2rem;
						margin-bottom: 5px;
					}
				}

				&__content {
					&::before {
						bottom: 1.5rem;
						right: 1.5rem;
						top: auto;

						@include minW(992) {
							top: 3rem;
							right: 3rem;
							bottom: auto;
						}
					}
				}
			}
		}

		&--result {
			padding-bottom: 0;
		}

		.variant-box {
			.title {
				padding-right: 4rem;
			}

			.ces-noted {
				margin-top: 1rem;
			}
		}
	}

	.car-degrees {
		opacity: 0;
		z-index: 1;

		&.is-active {
			opacity: 1;
			z-index: 2;
		}
	}
}

.configurator-result {
	position: relative;

	&__bg {
		right: 0;
		height: calc(100% + 14.1rem);
		top: -14.1rem;
		position: absolute;
		text-align: right;
		width: 100%;
		background: linear-gradient(90deg, hsla(0, 0%, 97%, 0), hsla(0, 0%, 97%, 0) 19.9999%, #f7f7f7 30%, #f7f7f7);

		&::before {
			content: "";
			display: block;
			background-color: #fff;
			height: 100%;
			position: absolute;
			width: 200%;
			top: 0;
			left: 0;
			transform: translateX(-73%) skew(-30deg);
		}
	}

	&__main {
		position: relative;
		z-index: 2;
	}

	&__button {
		display: flex;
		flex-direction: column;

		.btn {
			width: 100%;
			margin-bottom: 1.6rem;
		}
	}

	.row {
		&__col {
			@include minW(992) {
				order: 1;
			}

			&:last-child {
				@include minW(992) {
					order: 0;
				}
			}
		}
	}
}

.fabric {
	position: relative;
	margin-bottom: 4rem;
	overflow: hidden;

	&__item {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		margin-bottom: 6rem;
		opacity: 0;
		z-index: -1;

		&:first-child {
			position: relative;
		}

		&.is-active {
			opacity: 1;
			z-index: 2;
		}
	}
}

.card-configure {
	position: relative;
	display: flex;
	padding-top: 2.2rem;
	margin: 0 -5px 5rem;

	@include minW(992) {
		text-align: center;
		align-items: center;
		flex-direction: column;
		margin: 0 0 10rem 0;
	}

	&__body,
	&__thumb {
		flex-basis: 50%;
		max-width: 50%;
		padding: 0 5px;

		@include minW(992) {
			flex-basis: 100%;
			max-width: 100%;
			padding: 0;
		}
	}

	&__new3d {
		text-align: center;
		background-color: $red-color;
		border-radius: 4px;
		text-transform: uppercase;
		color: $white-color;
		font-size: 1.1rem;
		line-height: 1.1rem;
		font-weight: 700;
		padding: 5px 1.5rem 8px;
		margin-bottom: 5px;

		@include minW(992) {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			margin-bottom: 0;
		}
	}

	&__title {
		font-size: 2rem;
		line-height: 2.5rem;
		margin-bottom: 5px;
		font-weight: 500;
	}
}
