.fleetsale {
	position: relative;
	overflow: hidden;

	&.is-blur {
		opacity: 0.6;
		pointer-events: none;
		cursor: default;
	}

	&__intro {
		position: relative;
		padding: 3rem 2rem 0;

		@include minW(992) {
			padding: 13rem 0 0;
		}

		ul {
			display: block;
			list-style: none;
			margin-left: 0;
			padding-left: 0;

			@include minW(992) {
				display: flex;
			}

			li {
				display: flex;
				flex: 1;
				align-items: center;
				font-size: 1.4rem;
				line-height: 1.6rem;
				font-weight: 700;
				margin-left: 3rem;
				margin-bottom: 1.6rem;

				@include minW(992) {
					margin-bottom: 0;
				}

				img {
					margin-right: 1rem;
					max-width: 1.6rem;
				}
			}
		}
	}

	&__thumb {
		margin-bottom: 1.6rem;

		@include minW(992) {
			margin-bottom: 0;
		}
	}

	&__head {
		text-align: center;
	}

	&__title {
		text-align: center;
		margin-bottom: 6rem;

		span {
			display: block;
		}
	}

	.form {
		padding: 8rem 0;
	}
}

.register-career {
	position: relative;
	overflow: hidden;
	padding-bottom: 6rem;

	&__title {
		margin-bottom: 6rem;
		text-align: center;
	}

	&.is-blur {
		opacity: 0.6;
		pointer-events: none;
		cursor: default;
	}
}
