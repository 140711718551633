.locations {
	position: relative;

	&__body {
		margin: 0 -2rem;

		@include minW(992) {
			margin: 0;
		}
	}

	.table {
		width: 100%;
		max-width: 100%;
		min-width: 64rem;
		margin-bottom: 1rem;
		background-color: transparent;
		border-collapse: collapse;

		&-responsive {
			display: block;
			width: 100%;
			overflow-x: auto;
			-webkit-overflow-scrolling: touch;
			-ms-overflow-style: -ms-autohiding-scrollbar;
			margin-bottom: 3rem;
			padding: 0 2rem;

			@include minW(992) {
				padding: 0;
			}
		}

		thead {
			&.thead-dark {
				th {
					background-color: #f3f3f3;
				}
			}

			th {
				vertical-align: bottom;
				width: 15%;

				&:nth-child(2) {
					width: 40%;
				}

				&:nth-child(3) {
					width: 20%;
				}

				&:last-child {
					width: 15%;
				}
			}
		}

		tbody {
			tr {
				&:nth-child(2n+1) {
					background-color: #fff;
				}
			}
		}

		td,
		th {
			padding: 0.75rem;
			vertical-align: top;
			border: 1px solid #fbfbfb;
			text-align: center;
		}
	}

	.region {
		&__select {
			.select {
				max-width: 100%;
			}
		}
	}
}
