.select2-container {
	display: block;
	margin: 0;
	position: relative;
	outline: 0;
	z-index: 9;

	&--disabled {
		.select2-selection {
			border-color: $light-grey-color !important;

			&__rendered {
				color: $dark-grey-color !important;
			}
		}
	}

	&--focus {
		.select2-selection {
			border-color: $black-color;
		}
	}

	&--open {
		z-index: 100;

		.select2-selection {
			&__arrow {
				top: 1.4rem;

				&::before {
					transform: rotate(135deg);
				}
			}
		}
	}
}

.select2-hidden-accessible {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
	outline: 0;

	&:focus {
		outline: 0;
	}
}

.select2-selection {
	position: relative;
	display: block;
	border: 0;
	border-bottom: 1px solid #C8C8C8;
	border-radius: 0;
	outline: 0;

	&__rendered {
		display: block;
		height: 3.5rem;
		line-height: 3.5rem;
		font-size: 1.2rem;
		color: #5A646E;
		text-transform: uppercase;
		user-select: none;
		outline: none;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		padding-right: 2rem;

		&--value {
			font-size: 1.6rem;
			color: $black-color;
			text-transform: capitalize;
			font-weight: 500;
		}
	}

	&__arrow {
		position: absolute;
		right: 0;
		top: 1.2rem;

		&::before {
			content: '';
			display: block;
			width: 8px;
			height: 8px;
			border-left: 2px solid $dark-grey-color;
			border-bottom: 2px solid $dark-grey-color;
			transform: rotate(-45deg);
		}
	}
}

.select2-dropdown {
	display: block;
	background-color: $white-color;
	box-shadow: 0 0 5px $dark-grey-color;
}

.select2-search {
	display: block;
	padding: 1rem;

	&--hide {
		display: none;
	}

	&__field {
		width: 100%;
		outline: 0;
		height: 3.5rem;
		font-size: 1.2rem;
		font-weight: 400;
		line-height: 3.5rem;
		border: 1px solid #C8C8C8;
		padding: 1.2rem;
		box-shadow: none;

		&:focus {
			outline: 0;
		}
	}
}

.select2-results {
	position: relative;
	display: block;

	&__options {
		list-style: none;
		padding: 0;
		margin: 0;
		max-height: 21rem;
		overflow-y: auto;
	}

	&__option {
		display: block;
		height: 3.5rem;
		line-height: 3.5rem;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		font-size: 1.2rem;
		color: $dark-grey-color;
		padding: 0 1rem;
		cursor: pointer;

		&--group {
			text-transform: uppercase;
		}

		&--selected {
			background-color: $grey-color;
		}

		&--highlighted {
			background-color: $light-grey-color;
		}

		&--selectable {
			padding: 0 2rem;
		}

		&--disabled {
			display: none;
		}
	}
}
