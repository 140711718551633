.car-hero {
	position: relative;
	min-height: 35rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12.4rem 0 2.4rem;

	&__body {
		text-align: center;
	}

	&__desc {
		@include minW(768) {
			padding: 0 12.5%;
		}
	}
}

.our-range-catalog {
	position: relative;
	margin-bottom: 4rem;
	.row {
		@include minW(992) {
			justify-content: center;
			text-align: center;
		}
	}
}

.our-range-card {
	position: relative;
	margin: 0 -2rem;

	.btn {
		width: 100%;
	}

	@include minW(992) {
		margin: 0;

		.btn {
			width: auto;
		}
	}

	&__thumb {
		margin-bottom: 3.4rem;
	}

	&__body {
		padding: 0 2rem 3.2rem;

		@include minW(992) {
			padding: 0 4rem 4rem;
		}
	}

	&__price {
		font-size: 2rem;
		line-height: 2.5rem;
		margin-bottom: 2rem;
	}

	&__desc {
		margin-bottom: 3rem;
	}

	&__spec {
		display: flex;
		padding: 0;
		margin-bottom: 3.2rem;
		list-style: none;
	}

	&__icon {
		display: flex;
		align-items: center;
		margin-left: 1.6rem;
		text-transform: uppercase;
		font-size: 1.2rem;
		line-height: 1.8rem;
		font-weight: 700;

		&:first-child {
			margin-left: 0;
		}

		.img {
			width: 2.4rem;
			margin-right: 1rem;
		}
	}

	.heading-three {
		margin-bottom: 1rem;
	}
}
