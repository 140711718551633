.qr-code {
	position: relative;
	width: 100%;
	min-height: 100vh;
	display: flex;
	align-items: center;
	text-align: center;

	&__main {
		width: 100%;
		padding: 6rem 2rem;
	}


	&__logo {
		display: flex;
		justify-content: center;
		margin-bottom: 3.2rem;

		.img {
			min-width: 12rem;
		}
	}

	&__id {
		font-size: 3rem;
		font-weight: 700;
		letter-spacing: 3px;
		margin-top: 1rem;
	}

	&__note {
		font-size: 1.8rem;
		line-height: 2.4rem;

		span {
			display: block;
		}
	}
}
