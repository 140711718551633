.img {
	display: block;
	max-width: 100%;

	&--full {
		width: 100%;
	}
}
img {
	max-width: 100%;
}
