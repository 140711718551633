.company-intro {
  position: relative;
  text-align: center;

  &__main {
    width: 100%;
    max-width: 132rem;
    padding: 0 2rem;
    margin: 0 auto;
  }

  &__body {
    padding: 5rem 0 10rem;

    .title {
      margin-bottom: 3rem;
    }
  }

  &__content {
    color: $dark-grey-color;
  }
}
