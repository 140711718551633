.cost-estimate {
	position: relative;
	overflow: hidden;
	padding: 5rem 0 10rem;

	&__title {
		text-align: center;
		margin-bottom: 9rem;
	}

	&__button {
		text-align: center;
	}
}

.car-degrees-wrap {
	position: relative;
	width: 100%;
	height: 100%;
}

.car-degrees {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin-bottom: 6rem;
	opacity: 0;
	z-index: -1;

	&:first-child {
		position: relative;
	}

	&.is-active {
		opacity: 1;
		z-index: 1;
	}

	&__control {
		font-size: 1.2rem;
		line-height: 1.6rem;
		letter-spacing: 2px;
		color: $dark-grey-color;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__prev,
	&__next {
		font-size: 0;
		width: 1rem;
		height: 1rem;
		cursor: pointer;
		transition: all 0.3s ease-out;

		@include hover {
			border-color: $red-color;
		}
	}

	&__prev {
		border-left: 2px solid $dark-grey-color;
		border-bottom: 2px solid $dark-grey-color;
		transform: rotate(45deg);
		margin-right: 5px;
	}

	&__next {
		border-right: 2px solid $dark-grey-color;
		border-bottom: 2px solid $dark-grey-color;
		transform: rotate(-45deg);
		margin-left: 5px;
	}

	&__paging {
		span {
			padding: 0 5px;
		}
	}
}

.variant-choose {
	position: relative;
	margin-bottom: 19rem;
	display: none;

	>.row {
		align-items: center;
	}
}

.variant-box {
	position: relative;

	.row {
		&__col {
			margin-bottom: 4rem;
		}
	}

	&__item {
		position: relative;
		width: 100%;
		background-color: #f6f6f6;
		height: 100%;

		&.is-active {
			.variant-box {
				&__content {
					border-color: $red-color;
				}
			}
		}

		input[type="radio"] {
			position: absolute;
			opacity: 0;
			z-index: -1;

			&:checked {
				+.variant-box__content {
					&::before {
						background-color: $red-color;
						border-color: $black-color;
						box-shadow: inset 0 0 0 3px $white-color;
					}
				}
			}
		}
	}

	&__content {
		padding: 2.5rem;
		border-top: 2px solid $black-color;

		&::before {
			content: '';
			display: block;
			width: 2.2rem;
			height: 2.2rem;
			border: 1px solid $black-color;
			border-radius: 50%;
			position: absolute;
			top: 3rem;
			right: 3rem;
			background-color: $white-color;
		}
	}

	&__desc {
		span {
			display: block;
		}
	}

	.ces-noted {
		margin-top: 0;
	}
}
